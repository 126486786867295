import "primereact/resources/primereact.min.css";
import React from 'react';
import { Route, Routes } from "react-router-dom";
import './App.css';
import "./components/public/themes/saga-blue/theme.css";
//__________________________________________________________
//                  Constet
import Sidebar from './components/Helper/sidebarr';
//__________________________________________________________
//                  Pages
import Error from "./components/Home/error";
import Home from "./components/Home/home";
import Login from "./components/Home/login";
import Logout from "./components/Home/logout";
import BookigNew from './components/bookings/booking_new';
import Bookinges from './components/bookings/bookinges';
import Payments from './components/bookings/payments/payments';
import CustomerNew from "./components/customers/customer_new";
import Customers from "./components/customers/customers";
import ReportBookinges from "./components/reports/report_bookinges";


function App() {
  const TOKEN =   window.localStorage.getItem('isLogIn');
  return (
    //sadasd
    <>
      <Routes basename="/">
        <Route path="/" element={TOKEN?<><Sidebar/><Home/></>:<Login/>} />
        <Route path="/*" element={<><Error/></>} />
        <Route path="/login" element={<><logIn/></>} />
        <Route path="/error" element={<><Error/></>} />
        <Route path="/logout" element={<><Logout/></>} />
        <Route path="/home"  element={<><Sidebar/><Home/></>} />
        <Route path="/booking-new"  element={TOKEN ? <><Sidebar/><BookigNew/></> : <Logout/>} />
        <Route path="/bookings"  element={<><Sidebar/><Bookinges/></>} />
        <Route path="/payments"  element={<><Sidebar/><Payments/></>} />
        <Route path="/customer-new"  element={<><Sidebar/><CustomerNew/></>} />
        <Route path="/customers"  element={<><Sidebar/><Customers/></>} />
        <Route path="/report_bookinges"  element={<><Sidebar/><ReportBookinges/></>} />

      </Routes>
     </>
  );
}

export default App;
