import axios from "axios";
import { Button } from 'primereact/button';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flip, ToastContainer, toast } from 'react-toastify';
import { linkLogin } from "../Helper/links";
import './login.css';

function Login()
{
    //localStorage.clear();
   const navigate = useNavigate();
   const[loading_login,set_loading_login] = useState(false)
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const userlogin = async (e) =>
   {
     e.preventDefault();
     set_loading_login(true);
     if(!username  && username === "" && !password && password ==="" )
     {
         set_loading_login(false);
         toast.error("ارجو ادخال اسم المستخدم و كلمة المرور  ", {
           position: "top-center",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
           });
       //errorhandler()
     }
     else if(username.match((/^[a-zA-Z]+$/)) === false )
     {
       set_loading_login(false);
       toast.error(" [a_z.A_Z] ارجو ادخال اسم المستخدم بطريقة صحيحة", {
         position: "top-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
         });
     }
     else
     {
      var response = await axios.post( linkLogin, {
         username: username,
         password: password,
       })
         set_loading_login(false);
         if(response.status===200)
         {
            if (response.data.success === 1)
            {
              window.localStorage.setItem("isLogIn", true);
              window.localStorage.setItem("username", response.data.user.username);
              window.localStorage.setItem("name", response.data.user.name);
              window.localStorage.setItem("phone", response.data.user.phone);
              window.localStorage.setItem("type", response.data.user.type);
              window.localStorage.setItem("token" , response.data.user.token);
              //_____________________________________________________________________
              window.localStorage.setItem("resort_name_en" , response.data.resort.name_en);
              window.localStorage.setItem("resort_name_ar" , response.data.resort.name_ar);
              window.localStorage.setItem("short_resort_name" , response.data.resort.short_name);
              window.localStorage.setItem("resort_phone_1" , response.data.resort.phone_1);
              window.localStorage.setItem("resort_phone_2" , response.data.resort.phone_2);
              window.localStorage.setItem("resort_email" , response.data.resort.email);
              window.localStorage.setItem("resort_website" , response.data.resort.website);
              window.localStorage.setItem("resort_logo_path" , response.data.resort.logo_path);
              window.location.href = "./";

            }
            if(response.data.success === 500 || response.data.error === 1)
            {
              navigate('/error',{
                state : {
                  msg : response.data.msg
                }
              })
            }
            else if(response.data.success === 0)
            {
              toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

            }
        }

     }
   };

    return (
      <>
        <div className="full_container bgbgb" dir="rtl" style={{ backgroundColor: "rgb(21, 40, 60)" }}>
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={true}
            transition={Flip}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />
            <div className="container" style={{paddingTop:"100px"}}>
                <div className="center verticle_center full_height ">
                    <div className="login_section" style={{width:"600px",maxHeight:"700px"}}>
                      <center>
                        <div className="logo_login" style={{
                          background : "url(https://yoursummer.ly/logo.png)",
                          width: "300px",
                          height: "300px",
                          // padding: "120px 100px",
                          backgroundPosition: "center" ,
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundSize:"300px ",

                      }} >

                        </div>
                        </center>
                        <hr></hr>
                        <div className="login_form" >
                        <form>
                            <fieldset>
                            <div className="field" >
                                <label  className="label_field" style={{float: "right"}}>
                                اسم المستخدم
                                </label>
                                <input
                                id="user"
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>

                            <div className="field" style={{paddingTop: "50px"}}>
                                <label className="label_field" style={{float: "right"}}>كلمة المرور</label>
                                <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                          </fieldset>
                          <center >
                            <br></br>
                            <div className="col-md-12">
                              <Button dir="ltr" label=" تسجيل دخول " raised loading={loading_login}   style={{fontFamily:"Cairo",backgroundColor:"rgb(20, 176, 204)"}} onClick={e=>userlogin(e)} />
                            </div>
                          </center>
                        </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </>

    );
}
export default Login;