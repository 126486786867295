import axios from "axios";
import Moment from 'moment';
import { addLocale } from 'primereact/api';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { editbooking, getapartment_dates_resort, getcustomers } from "../../Helper/links";
import pattern from "../../Helper/pattern";
import count_days from '../../functions/count_days';
import view_day_ar from "../../functions/view_day_ar";
import { InvoiceContext } from './Invoice_Context';
import InvoiceCustomer from './invoice_customer';

import date_minus_day from "../../functions/date_minus_day";
import view_apartment_type_ar from "../../functions/view_apartment_type";
import './invoice.css';
let today = new Date();
let booking_apartment_dates = {};
function EditReservationes()
{
    addLocale('ar', {
        dir:"rtl",
        firstDayOfWeek: 6,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
        monthNames: ['January-01', 'February-02', 'March-03', 'April-04', 'May-05', 'June-06', 'July-07', 'August-08', 'September-09', 'October-10', 'November-11', 'December-12']    ,
        monthNamesShort: ['Jan-01', 'Feb-02', 'Mar-03', 'Apr-04', 'May-05', 'Jun-06', 'Jul-07', 'Aug-08', 'Sep-09', 'Oct-10', 'Nov-11', 'Dec-12'],
        today: 'اليوم',
        clear: 'تفريغ',
      });

    const [loading_editt,set_loading_editt]= useState(false);
    const [apartment, setApartment] = useState({"dates_reservationes":[]});
    const data = useContext(InvoiceContext)
    const Navigate = useNavigate();
    //___________________________________________________________________________________________
    const [ID_reservation, setID_reservation]= useState(data.ID_reservation);
    const [dates, setDates] = useState([new Date(data.date_check_in),date_minus_day(new Date(data.date_check_out),1)]);
    const [customers, setCustomers]= useState([]);
    const [id_customer, setid_customer]= useState(data.ID_customer);
    const [visitor_name, setvisitor_name] = useState(data.visitor_name);
    const [phone_number, setphone_number] = useState(data.phone_number);
    const [whatsapp_number, setwhatsapp_number] = useState(data.whatsapp_number ? "+"+data.whatsapp_number:null);
    const [notes, setnote] = useState(data.notes);
    const [discount_value, setdiscount_value]= useState(data.discount_value?data.discount_value:0);
    const [price_day, setprice_day]= useState(data.price_day);
    const [total_price, settotal_price]= useState(data.total_price);
    const [view_check_out,set_view_check_out] = useState(null);
    const [days,set_days]=useState(count_days([{"date_check_in":data.date_check_in,"date_check_out":data.date_check_out}]));
    //_______________________________________________________
    const [reservation,set_reservation] = useState(false);
    const [view_invoice_edit,set_view_invoice_edit] = useState(false);
    //_______________________________________________________
    //                      Customers
    const [filtered_customers, set_filtered_customers] = useState(null);
    const search_customer_by_name = (event) => {
      //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
      let query = event.query;
      let _filtered_customers = [];
      for(let i = 0; i < customers.length; i++) {
          let customer = customers[i];
          if (customer.visitor_name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            _filtered_customers.push(customer);
          }
      }
      set_filtered_customers(_filtered_customers);
    }
    const search_customer_by_phone = (event) => {
      //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
      let query = event.query;
      let _filtered_customers = [];
      for(let i = 0; i < customers.length; i++) {
          let customer = customers[i];
          if (customer.phone_number.toString().toLowerCase().indexOf(query.toLowerCase()) === 0) {
            _filtered_customers.push(customer);
          }
      }
      set_filtered_customers(_filtered_customers);
    }

    const handelcustomer_by_name =  (e) =>
    {
      var have   = false;
      if(e.target.value.ID_customer)
      {
         setid_customer(e.target.value.ID_customer)
         setvisitor_name(e.target.value.visitor_name)
         setphone_number(e.target.value.phone_number)
         setwhatsapp_number(e.target.value.whatsapp_number?'+'+e.target.value.whatsapp_number:null)
         document.getElementById("lvisitor_name").style.color = 'black';
         document.getElementById("lphone_number").style.color = 'black';
         have = true;
         document.getElementById('msg_customer').innerHTML="زبون مسجل مسبقا"
          return;
      }
      else
      {
        if(!have)
        {
          if(id_customer!==null && id_customer!=='')
          {
            setid_customer("");
            setphone_number("");
            setwhatsapp_number(null);
          }

        }
        if(pattern.name_en_ar.test(e.target.value) === false || e.target.value==="" || e.target.value.length>255)
        {
          document.getElementById("lvisitor_name").style.color = 'red';
        }
        else
        {
          document.getElementById("lvisitor_name").style.color = 'black';

        }
        setvisitor_name(e.target.value)
        document.getElementById('msg_customer').innerHTML="زبون جديد"
      }
    }
    const handelcustomer_by_phone =  (e) =>
    {
        var have   = false;
        if(e.target.value.ID_customer)
        {
          setid_customer(e.target.value.ID_customer)
          setvisitor_name(e.target.value.visitor_name)
          setphone_number(e.target.value.phone_number)
          setwhatsapp_number('+'+e.target.value.whatsapp_number)
          document.getElementById("lvisitor_name").style.color = 'black';
          document.getElementById("lphone_number").style.color = 'black';
          have = true;
          document.getElementById('msg_customer').innerHTML="زبون مسجل مسبقا"
            return;
        }
        else
        {
          if(!have)
          {
            if(id_customer!==null && id_customer!=='')
            {
              setid_customer("");
              setvisitor_name("");
              setwhatsapp_number(null);
            }

          }
          if(pattern.phone_number.test(e.target.value)=== false || e.target.value.length!==9)
          {
            document.getElementById("lphone_number").style.color = 'red';
          }
          else{
            document.getElementById("lphone_number").style.color = 'black';
          }
          setphone_number(e.target.value)
          document.getElementById('msg_customer').innerHTML="زبون جديد"
        }

    }
    //___________________________________________________________________________________________
    const handeldiscount_value = (e) =>
    {
        var days=0;
        if(!dates[0])
        {
            days = count_days([{"date_check_in":data.date_check_in,"date_check_out":data.date_check_out}]);
        }
        else if(!dates[1])
        {
            days = count_days([{"date_check_in":dates[0],"date_check_out":dates[0]}]);

        }
        else
        {
            days = count_days([{"date_check_in":dates[0],"date_check_out":dates[1]}]);
        }
        days++;
        if(days<=0)
        {
            days=1;
        }
        set_days(days)
        setdiscount_value(e.target.value);
        settotal_price((days*price_day)-e.target.value)
        if(
        pattern.number_int.test(e.target.value) === true
        &&  (e.target.value) >= 0
        )
        {
            document.getElementById("ldiscount_value").style.color = "black";
        }
        else
        {
            document.getElementById("ldiscount_value").style.color = "red";
        }
    }
    const handelDates =  (e,dates_reservationes,id_apartment,not_date_check_in,not_date_check_out) =>
    {
        var days=null;
        var date_is_empty = true;
        if(!e.target.value)
        {
            setDates([]);
            document.getElementById("ldates").style.color="red"
        }
        else if(e.target.value && e.target.value.length>0 && Moment(e.target.value[0]).format('yyyy-MM-DD') > Moment(new Date()).format('yyyy-MM-DD'))
        {
            for(var i=0;i<dates_reservationes.length;i++)
            {
              if(
                (
                    (
                        Moment(e.target.value[0]).format("yyyy-MM-DD") >=  Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")
                        &&
                        Moment(e.target.value[0]).format("yyyy-MM-DD") <  Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")
                        &&
                        e.target.value[0] && e.target.value[0]!==null
                    )
                    ||
                    (
                        Moment(e.target.value[1]).format("yyyy-MM-DD") >=  Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")
                        &&
                        Moment(e.target.value[1]).format("yyyy-MM-DD") <  Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")
                        &&
                        e.target.value[1] &&e.target.value[1]!==null
                    )
                   ||
                    (
                        Moment(e.target.value[0]).format("yyyy-MM-DD") <=  Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")
                        &&
                        Moment(e.target.value[1]).format("yyyy-MM-DD") >=  Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")
                        &&
                        e.target.value[1] &&e.target.value[1]!==null
                    )
                )
                &&
                Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")!==Moment(not_date_check_in).format("yyyy-MM-DD")
                &&
                Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")!==Moment(not_date_check_out).format("yyyy-MM-DD")
              )
              {
                date_is_empty=false;
                break;
              }
            }
            if(date_is_empty)
            {
                setDates(e.target.value);
                setdiscount_value(0)
                booking_apartment_dates[id_apartment]=e.target.value;
                if(!e.target.value[1])
                {
                    days=count_days([{"date_check_in":e.target.value[0],"date_check_out":e.target.value[0]}]);
                    set_view_check_out(new Date(new Date(e.target.value[0]).setDate(e.target.value[0].getDate() + 1)))

                }
                else
                {
                    days=count_days([{"date_check_in":e.target.value[0],"date_check_out":e.target.value[1]}]);
                    set_view_check_out(new Date(new Date(e.target.value[1]).setDate(e.target.value[1].getDate() + 1)))
                }
                days++;
                days > 0? set_days(days):set_days(1)
                settotal_price(price_day*days);

                document.getElementById("ldates").style.color="black"
                document.getElementById("dates").style.color="black"

            }
            else
            {
                document.getElementById("ldates").style.color="red"
                if(e.target.value[1])
                document.getElementById("dates").style.color="red"


            }
        }
    }
    function dates_close(dates_reservationes,not_date_check_in,not_date_check_out)
    {
        var dates_disabled = [];
        if(dates_reservationes.length>=1)
        {
            // eslint-disable-next-line array-callback-return
            dates_reservationes.map((date)=>{

                var date_in = new Date(date.date_check_in);
                var date_out = new Date(date.date_check_out);
                if(
                    Moment(date_in).format("yyyy-MM-DD")!==Moment(not_date_check_in).format("yyyy-MM-DD")
                    &&
                    Moment(date_out).format("yyyy-MM-DD")!==Moment(not_date_check_out).format("yyyy-MM-DD")
                )
                {
                    while(Moment(date_in).format("yyyy-MM-DD") < Moment(date_out).format("yyyy-MM-DD"))
                    {
                        dates_disabled.push(new Date(Moment(date_in).format("yyyy-MM-DD")));
                        date_in.setDate(date_in.getDate() + 1);
                    }
                }
            })
            return dates_disabled;
        }
        else
        {
            return [];
        }
    }
    function dateTemplate  (date,dates_reservationes,not_date_check_in,not_date_check_out)
    {
      var day_test = new Date(date.year + "-" + (date.month+1)+"-" + date.day);
        for(var i=0;i<dates_reservationes.length;i++)
        {
          if(
            Moment(day_test).format("yyyy-MM-DD") >=  Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")
            &&
            Moment(day_test).format("yyyy-MM-DD") <  Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")
            &&
            Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")!==Moment(not_date_check_in).format("yyyy-MM-DD")
            &&
            Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")!==Moment(not_date_check_out).format("yyyy-MM-DD")
          )
          {
            return (
              <strong style={{ textDecoration: 'line-through' , color:"red"}}>{date.day}</strong>
            );
          }

        }
        return (
          <strong style={{color:"black"}}>{date.day}</strong>
        );
    }
     //_______________________________________________________________________________________
    //                      Update
    function EditReservationes   (e)
    {
        e.preventDefault();
        set_loading_editt(true);
        var test_validtion = true
        //_______________________________________________________________________________________
        //                         Validtion
        if(pattern.number_int.test(discount_value) === false
        || discount_value==="" || discount_value < 0
        )
        {
          document.getElementById("ldiscount_value").style.color = 'red';
          test_validtion = false;
        }
        if(pattern.name_en_ar.test(visitor_name) === false
        || visitor_name==="" || visitor_name.length>200)
        {
          document.getElementById("lvisitor_name").style.color = 'red';
          test_validtion = false
        }
        if(pattern.number_int.test(phone_number)=== false
        || phone_number==="" || phone_number.length>14)

        {
          document.getElementById("lphone_number").style.color = 'red';
          test_validtion = false
        }
        if(
            whatsapp_number!==null &&
            (pattern.number_whatsapp.test(whatsapp_number.toString().length>0 &&  whatsapp_number.toString()[0]!=='+'?'+'+whatsapp_number:whatsapp_number)=== false
            || whatsapp_number==="" || whatsapp_number.length>15)
          )
        {
          document.getElementById("lwhatsapp_number").style.color = 'red';
          test_validtion = false
        }
        if(
            !dates || !dates[0]
            // || Moment(dates[0]).format("yyyy-MM-DD") <Moment(new Date()).format("yyyy-MM-DD")
          )
        {
          document.getElementById("ldates").style.color = 'red';
          test_validtion = false
        }
        var data= {
            "TOKEN": window.localStorage.getItem('token'),
            "id_reservation":ID_reservation,
            "id_customer":id_customer,
            "visitor_name":visitor_name,
            "phone_number":phone_number,
            "whatsapp_number":whatsapp_number,
            "discount_value":discount_value,
            "date_check_in":dates.length>0 && dates[0] &&  Moment(dates[0]).format("yyyy-MM-DD")? Moment(dates[0]).format("yyyy-MM-DD"):null,
            "date_check_out":dates.length>=1 && dates[1] &&  Moment(dates[1]).format("yyyy-MM-DD")? Moment(dates[1]).format("yyyy-MM-DD"):null,
            "notes": notes,
        }
        //_______________________________________________________________________________________
        if(test_validtion)
        {
             axios.post( editbooking ,data
            ).then((res) =>
            {
                console.log(res.data)
              set_loading_editt(false);
              if(res.status === 200)
              {
                if (res.data.success === 500 || res.data.error === 1)
                {
                    Navigate('/error', {
                        state: {
                            msg: res.data.msg
                        }
                    })
                }
                else if(res.data.success===1)
                {
                    toast.success(res.data.msg, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    set_reservation(res.data.data)
                    set_view_invoice_edit(true)
                    //Navigate('/home')
                }
                else if(res.data.success===0)
                {
                  toast.error(res.data.msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                }
                else if(res.data.success===-1)
                {
                    toast.error(res.data.msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                    document.getElementById("ldates").style.color="red"
                }
              }
            }
            ).catch((err) =>
            {
              set_loading_editt(false);
              console.log(err)
            })
        }
        else
        {
          set_loading_editt(false);
          toast.error("ارجو تحقق من بيانات", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
    }
    //_______________________________________________________________________________________
    //                      Get Apartment
    const GetApartment = async (id_apartment) =>
    {
        var response = await axios.post(getapartment_dates_resort,{"TOKEN": window.localStorage.getItem('token'),"id_apartment":id_apartment});
        if (response.data.success === 500 || response.data.error === 1)
        {
            Navigate('/error', {
                state: {
                    msg: response.data.msg
                }
            })
        }
        else if(response.data.success===1)
        {
            setApartment(response.data['apartment']);
            booking_apartment_dates[apartment.ID_apartment] =[new Date(data.date_check_in),new Date(data.date_check_out)];
        }
    }
    //                      Get Customers
    const GetCustomers = async () =>
    {
        var response = await axios.post(getcustomers,{"TOKEN": window.localStorage.getItem('token'),"state":1});
        if (response.data.success === 500 || response.data.error === 1)
        {
            Navigate('/error', {
                state: {
                    msg: response.data.msg
                }
            })
        }
        else if(response.data.success===1)
        {
            setCustomers(response.data['customers']);
        }
    }
    useEffect(() =>
    {
        GetCustomers();
        GetApartment(data.ID_apartment);
    }, []);
    return (
    <div>
    {
        view_invoice_edit ?
        (
            <>
                <InvoiceContext.Provider value={{"customer":reservation}} >
                {reservation &&    <InvoiceCustomer></InvoiceCustomer>}
                </InvoiceContext.Provider>
            </>
        )
        :
        (<div id="invoice" className="page-content fade-in-up" dir="rtl">
            <div className="ibox invoice-print">
                <div className="invoice-header">
                    <div className="col-12">
                        <table className="table table-bordered table-striped no-margin table-invoice">
                        <thead className="color-view bg-ebony">
                            <tr style={{color:"white",fontSize:"10px"}}>
                                <th>الحجز</th>
                                <th>الاقامة</th>
                                <th>الأيام</th>
                                <th colSpan={2} style={{textAlign:"center"}} >
                                الدخول - الخروج
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize:"15px"}}>
                                <tr>
                                    <td>{data.ID_reservation}</td>
                                    <td>
                                        <div>
                                        <small>{view_apartment_type_ar(data.type)}</small><strong>{"-"+ data.number}</strong>
                                        </div>
                                        <small>{data.number_people} أشخاص </small>
                                    </td>
                                    <td >{count_days([data])} أيام</td>
                                    <td >
                                        <div>
                                            <strong >{data.date_check_in} </strong>
                                        </div>
                                        <small> {view_day_ar(data.date_check_in)} </small>
                                    </td>
                                    <td>
                                        <div>
                                            <strong>{data.date_check_out}  </strong>
                                        </div>
                                        <small>  {view_day_ar(data.date_check_out)} </small>
                                    </td>

                                </tr>
                         </tbody>
                        </table>
                        <table className="table table-bordered table-striped no-margin table-invoice">
                        <thead className="color-view bg-ebony">
                            <tr style={{color:"white",fontSize:"10px"}}>
                                <th>سعر اليـوم</th>
                                <th>الإجمالي</th>
                                <th>المدفوع</th>
                                <th>الباقي</th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize:"15px"}}>
                                <tr>
                                    <td>{data.price_day}د،ل</td>
                                    <td>{data.total_price}د،ل</td>
                                    <td>{data.total_paid}د،ل</td>
                                    <td>{data.total_price-data.total_paid}د،ل</td>

                                </tr>
                         </tbody>
                        </table>
                        <hr></hr>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <label id="ldates">تاريخ الحجز</label>
                                    <div dir="rtl" style={{fontFamily:"Cairo",textAlign:"center"}}>
                                        <Calendar
                                        inputId="dates"
                                        minDate={today}
                                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                        dateTemplate={(e) => dateTemplate(e,apartment.dates_reservationes,data.date_check_in,data.date_check_out)}
                                        disabledDates={dates_close(apartment.dates_reservationes,data.date_check_in,data.date_check_out)}
                                        value={dates}
                                        onChange={(e) => handelDates(e,apartment.dates_reservationes,data.ID_apartment,data.date_check_in,data.date_check_out)}
                                        selectionMode="range" hideOnRangeSelection
                                        readOnlyInput
                                        locale="ar" dir="rtl"
                                        showButtonBar
                                        disabled={Moment(data.date_check_in).format('yyyy-MM-DD') > Moment(new Date()).format('yyyy-MM-DD')?false:true}
                                        style={{width:"100%",fontFamily:"Cairo",padding:"0px"}}  panelStyle={{fontFamily: "Cairo"}}
                                        dateFormat="yy-mm-dd"
                                        />
                                    </div>
                                    <br></br>
                                    {
                                        dates.length>0 ?
                                        (
                                            <table className="table table-bordered table-striped no-margin table-invoice">
                                                <thead className="color-view bg-ebony">
                                                    <tr style={{color:"white",fontSize:"10px"}}>

                                                        <th colSpan={2} style={{textAlign:"center"}} >
                                                        الدخول - الخروج
                                                        </th>
                                                        <th style={{textAlign:"center"}} >
                                                         عدد الأيام
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{fontSize:"15px"}}>
                                                    <tr>
                                                    <td>
                                                        <div>
                                                            <strong >{Moment(dates[0]).format("yyyy-MM-DD")} </strong>
                                                        </div>
                                                        <small> {view_day_ar(dates[0])} </small>
                                                    </td>
                                                    <td>
                                                        <div>
                                                              <strong >
                                                                { view_check_out===null?
                                                                     Moment(data.date_check_out).format("yyyy-MM-DD")
                                                                    :
                                                                     Moment(view_check_out)?.format("yyyy-MM-DD")}
                                                                </strong>
                                                        </div>
                                                        <small> { view_check_out===null? view_day_ar(data.date_check_out):view_day_ar(view_check_out)} </small>
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        <strong >
                                                        {days} أيام
                                                        </strong>
                                                    </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        ):
                                        (null)

                                    }
                                </div>
                                <div className="col-md-6">
                                    <center>
                                        <div className="col-md-6 form-group">
                                            <label id="ldiscount_value">  قيمة الخصم</label>
                                            <div className="input-group flex-1">
                                                <InputNumber inputId="discount_value" name="discount_value" min={0} max={price_day*days}
                                                value={discount_value}
                                                onValueChange={(e) => handeldiscount_value(e)} disabled={days && days>0? false:true}
                                                />
                                                <span className="p-inputgroup-addon">
                                                    د.ل
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <table className="table no-border">
                                                <thead>
                                                    <tr>
                                                        <td className="font-bold font-18 text-center"> السعر الكــلي  </td>
                                                        <td className="font-bold font-18 text-center"> المدفوع  </td>
                                                        <td className="font-bold font-18 text-center"> الباقي  </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td  className="font-bold font-18 text-center" style={{padding:"1px"}}>
                                                            {total_price} د،ل
                                                        </td>
                                                        <td className="font-bold font-18 text-center" style={{padding:"1px"}}>{data.total_paid} د.ل</td>
                                                        <td className="font-bold font-18 text-center" style={{padding:"1px"}}>
                                                        {
                                                            data.total_paid*1>=total_price*1 ?
                                                            (
                                                                <strong style={{color:"green"}}>
                                                                    خالص
                                                                </strong>
                                                            )
                                                            :
                                                            (
                                                                <strong style={{color:"red"}}>
                                                                    {total_price - data.total_paid} د.ل
                                                                </strong>
                                                            )
                                                        }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {
                                            data.total_paid>total_price ?
                                            (
                                                <>
                                                    <hr></hr>
                                                    <center>
                                                        <h5 id="msg_rest" style={{fontSize:"18px"}}>
                                                            القيمة المسترجعة
                                                        </h5>
                                                        <h5 id="rest" style={{fontSize:"18px",color:"red"}}>
                                                            {total_price-data.total_paid} د،ل
                                                        </h5>
                                                    </center>
                                                </>
                                            ):null
                                            }

                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-md-6">
                                <label id="lvisitor_name"> الأسم بالكامل *
                                    <i className="fa fa-user pr-1" ></i>
                                </label>
                                < label id="msg_customer" style={{paddingRight:"50px"}}></label>
                                <AutoComplete value={visitor_name} suggestions={filtered_customers} completeMethod={(e)=> search_customer_by_name(e)}
                                maxLength={255} placeholder=" الاسم" className="col-md-12 pr-0" style={{fontFamily:"Cairo"}} inputStyle={{fontFamily:"Cairo"}} panelStyle={{fontFamily:"Cairo",textAlign:"right"}}
                                virtualScrollerOptions={{ itemSize: 38 }} field="visitor_name" dropdown onChange={(e) => handelcustomer_by_name(e)} scrollHeight="400px" />

                            </div>

                            <div className="col-md-3">
                                <label id="lphone_number"> رقم الهاتف *
                                <i className="fa fa-phone pr-1" ></i>
                                </label>
                                <div className="input-group" >
                                <AutoComplete value={phone_number} suggestions={filtered_customers} completeMethod={(e)=> search_customer_by_phone(e)}
                                maxLength={9} placeholder="9x1234567"  className="col-md-12 pr-0" style={{fontFamily:"Cairo"}} inputStyle={{fontFamily:"Cairo"}} panelStyle={{fontFamily:"Cairo",textAlign:"right"}}
                                virtualScrollerOptions={{ itemSize: 38 }} field="phone_number" dropdown onChange={(e) => handelcustomer_by_phone(e)} scrollHeight="400px"  />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label id="lwhatsapp_number"> رقم واتس اب
                                <i className="fa fa-whatsapp pr-1" ></i>
                                </label>

                                <div className="input-group" dir="ltr">
                                <PhoneInput
                                    country='LY'
                                    defaultCountry="LY"
                                    placeholder="ادخل رقم واتس اب"
                                    value={whatsapp_number}  maxLength={15} name="whatsapp_number" id="whatsapp_number"
                                    onChange={setwhatsapp_number}
                                    className="p-inputtext p-component p-autocomplete-input p-autocomplete-dd-input p-autocomplete-input p-autocomplete-dd-input col-md-12 pr-0"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 form-group pt-3">
                                <label id="lnote">ملاحظات</label>
                                <div className="input-group">
                                    <textarea className="form-control " rows="4" cols="50"  name="note" id="note"
                                    placeholder="ادخال ملاحظات الحجز"  onChange={e=> setnote(e.target.value)} value={notes} maxLength={1000}  ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <Button dir='ltr'  label=" حفظ تعديل  " raised severity="success"
                                onClick={(e) => EditReservationes(e)} loading={loading_editt}
                                style={{fontFamily:"Cairo",direction:"ltr",marginRight:"10px"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    </div>
    );
}
export default EditReservationes;