
import moment from 'moment';
import { Button } from 'primereact/button';
import React, { useContext, useRef, useState } from 'react';
import QRCode from "react-qr-code";
import ReactToPrint from 'react-to-print';
import { linkLogosResort } from "../../Helper/links";
import { NAME, RESORT_EMAIL, RESORT_LOGO_PATH, RESORT_NAME_AR, RESORT_NAME_EN, RESORT_PHONE_1, RESORT_WEBSITE } from '../../Helper/user';
import count_days from '../../functions/count_days';
import sum_total_paid from '../../functions/sum_total_paid';
import sum_total_price from '../../functions/sum_total_price';
import view_apartment_type_ar from '../../functions/view_apartment_type';
import view_day_ar from '../../functions/view_day_ar';
import apple_stor from '../../public/images/apple_stor.svg';
import google_play from '../../public/images/google_play.png';
import logo from '../../public/images/logo.png';
import { InvoiceContext } from './Invoice_Context';
import './invoice.css';

function InvoiceBookinges()
{
    const data = useContext(InvoiceContext)
    const componentRef = useRef();
    const buttonRef = useRef();
    const [isShown, setIsShown] = useState(false);
    var monthView = 0;
    const datasort = [...data].sort((a, b) =>
    a.date_check_in > b.date_check_in ? 1 : -1,
    );

    return (
    <div>
        <div id="invoice" className="page-content fade-in-up" dir="rtl"  style={{margin:"auto",maxWidth:"850PX"}}  ref={componentRef}>
            <div className="ibox invoice-print" >
                <div className="invoice-header">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3">
                                    <div className="col-12 text-right" style={{paddingTop:"50px"}}>
                                        <div className="clf" >
                                            <dl className="row pull-right" >
                                                <dt className="col-sm-12">المكتب الحجز</dt>
                                                <dd className="col-sm-12" style={{paddingRight:"15px",margin:"0"}}>طرابلس - غرور</dd>
                                                <div style={{ height: "auto", marginTop:"2px",paddingRight:"15px", maxWidth: 70, width: "100%" }}>
                                                <QRCode
                                                    size={200}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={"https://"+RESORT_WEBSITE}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                </div>
                                            </dl>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={linkLogosResort +RESORT_LOGO_PATH} height="150px" width="300px"  alt='logo'/>
                                        </div>
                                    </center>
                                </div>

                                {/* <div className="col-3">
                                    <div className="col-12 text-left" style={{paddingTop:"50px"}}>
                                        <div className="clf" >
                                            <dl className="row pull-left"  >
                                                <dt className="col-sm-12">تاريخ الفاتورة</dt>
                                                <dd className="col-sm-12" style={{paddingLeft:"15px",margin:"0"}}>
                                                {moment(year + "-" +(month+1)+"-"+day).format("yyyy-MM-DD")}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-3">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={logo} height="100px" width="100px"  alt='logo_ys'/>
                                            <br></br>
                                            <img src={apple_stor} height="26px"  alt='logo_apple_stor' style={{marginTop:"5px"}}/>
                                            <br></br>
                                            <img src={google_play} height="35px"   alt='logo_google_play'/>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <hr/>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                            <li className="m-b-5">
                                            عدد الحجوزات : <span className="font-strong"> {datasort.length}</span></li>
                                            <li className="m-b-5">
                                            إجمالي الأيام :  <span className="font-strong"> {count_days(datasort)} </span> </li>
                                    </div>
                                    <div className="col-6" style={{textAlign:"left"}}>
                                        تاريخ الفاتورة
                                        <br></br>
                                        {moment(new Date()).format("YYYY-MM-DD")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-bordered table-striped no-margin table-invoice">
                        {
                            datasort.map((res)=>{
                                return(
                                <>
                                {
                                    monthView !== moment(res.date_check_in).format('MM')?
                                    (
                                        <>

                                        <thead className="color-view bg-white ">
                                             <tr className="color-view bg-white ">
                                                <td colSpan={12} >
                                                    <center>
                                                        <strong >
                                                        {" حجوزات شهر "}
                                                        { monthView = moment(res.date_check_in).format('MM')}
                                                        {" " + moment(res.date_check_in).format('MMMM') + " "}
                                                        </strong>
                                                    </center>
                                                </td>
                                            </tr>
                                            <tr style={{fontSize:"10px"}}>
                                                <th>الحجز</th>
                                                <th> الزبون</th>
                                                <th>الشالية</th>
                                                <th colSpan={2} style={{textAlign:"center",width:"20%"}} >
                                                الدخول - الخروج
                                                </th>
                                                <th>الأيام</th>
                                                <th>السعر اليوم</th>
                                                <th>الإجمالي</th>
                                                <th>المدفوع</th>
                                                <th>الباقي</th>
                                                <th>التخفيض</th>
                                                <th style={{textAlign:"center",width:"10%"}}>تاريخ  إلغاء </th>

                                            </tr>
                                        </thead>
                                    </>
                                    )
                                    :
                                    (null)
                                }
                                <tbody style={{fontSize:"13px"}}>
                                    <tr>
                                        <td>
                                        {res.ID_reservation}
                                        </td>
                                        <td>
                                            <div>
                                                <strong >{res.visitor_name}</strong>
                                            </div>
                                            <small>{res.phone_number}</small>
                                        </td>
                                        <td>
                                            <div>
                                            <small>{view_apartment_type_ar(res.type)}</small><strong>{"-"+ res.number}</strong>
                                            </div>
                                            <small>{res.number_people} أشخاص </small>
                                        </td>
                                        <td  >
                                            <div>
                                                <strong >{res.date_check_in} </strong>
                                            </div>
                                            <small> {view_day_ar(res.date_check_in)} </small>
                                        </td>
                                        <td >
                                            <div>
                                                <strong>{res.date_check_out}  </strong>
                                            </div>
                                            <small>  {view_day_ar(res.date_check_out)} </small>
                                        </td>
                                        <td style={{width:"5s%"}}>{count_days([res])} أيام</td>
                                        <td>{res.price_day}  د.ل</td>
                                        <td><strong >{res.total_price} د.ل</strong></td>
                                        <td><strong >{res.total_paid} د.ل</strong></td>
                                        <td>
                                            {
                                                res.total_price-res.total_paid ===0 ?
                                                (
                                                    <strong style={{color:"green"}}>
                                                        خالص
                                                    </strong>
                                                )
                                                :
                                                (
                                                    <strong style={{color:"red"}}>
                                                    {res.total_price-res.total_paid} د.ل
                                                    </strong>
                                                )
                                            }
                                        </td>
                                        <td><strong >{res.discount_value===0? '-':res.discount_value+'د.ل'}</strong></td>
                                        <td >
                                            {
                                                res.date_del_reservation?
                                                <strong style={{color:"red"}}>
                                                    {moment(res.date_del_reservation).format('MM-DD')}
                                                    <br></br>
                                                    {moment(res.date_del_reservation).format('LT')}
                                                </strong>
                                                :"-"
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                                </>
                                );
                            })
                        }
                </table>
                <table className="table no-border">
                    <thead>
                        <tr>
                            <td className="font-bold font-18 text-center">إجمالي المبلغ  </td>
                            <td className="font-bold font-18 text-center">إجمالي المدفوع  </td>
                            <td className="font-bold font-18 text-center">إجمالي المتبقي  </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td  className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_price(data)}
                                 د.ل</td>
                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_paid(data)} د.ل</td>
                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>
                            {
                                sum_total_price(data)-sum_total_paid(data) ===0 ?
                                (
                                    <strong style={{color:"green"}}>
                                        خالص
                                    </strong>
                                )
                                :
                                (
                                    <strong style={{color:"red"}}>
                                        {sum_total_price(data)-sum_total_paid(data)} د.ل
                                    </strong>
                                )
                            }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr/>
            <table className="table no-border">
                <thead>
                    <tr>
                        <th  colSpan={10}>
                            <center>
                            نتمني لكم قضاء صيف رائع في {RESORT_NAME_AR} - {RESORT_NAME_EN}
                            </center>

                        </th>
                    </tr>
                    <tr>
                        <th>
                            <div className="font-13">
                            <b> {RESORT_NAME_AR} - {RESORT_NAME_EN}  </b> <i className='fa fa-flag'></i>
                            </div>
                        </th>
                        <th>
                            <div className="font-13">
                            <b >  {RESORT_PHONE_1} </b>  <i className='fa fa-phone'></i>
                            </div>
                        </th>
                        <th>
                            <div className="font-13">
                            <b > www.{RESORT_WEBSITE}  </b> <i className='fa fa-globe'></i>
                            </div>
                        </th>
                        <th>
                            <div className="font-13">
                            <b > {RESORT_EMAIL} </b> <i className="fa fa-envelope"></i>
                            </div>
                        </th>
                        <th>
                            <div className="font-13">
                            <b > {NAME}  </b> <i className='fa fa-user'></i>
                            </div>
                        </th>

                    </tr>
                </thead>
            </table>
            <hr/>
        </div>

        <div className="text-center">
            <ReactToPrint
                trigger={
                    () =>  <Button dir='ltr' type="button"  label=" طبــاعة "  icon='fa fa-print' raised
                    ref={buttonRef}  onClick={(e)=> setIsShown(current => !current)}
                    />
                }
                content={() => componentRef.current}
            />
        </div>
    </div>
    );
}
export default InvoiceBookinges;