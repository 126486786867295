import Moment from 'moment';
const count_days =  function(res)
{
    var sum=0
    for(var i=0;i<res.length;i++)
    {
      var diffInMs   = new Date(Moment(res[i].date_check_out).format("yyyy-MM-DD")) - new Date(Moment(res[i].date_check_in).format("yyyy-MM-DD"));
      var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      sum+=diffInDays;
    }
    return sum;
}
export default count_days;