import { Apartments_Types } from "../Helper/data_static.tsx";

const view_apartment_type_ar =  function(type)
{
    switch (type) {
        case Apartments_Types.villa:
          return 'فيلا';
          case Apartments_Types.apartment:
          return 'شقة';
          case Apartments_Types.studio:
          return 'استديو';
          case Apartments_Types.chalet:
          return 'شاليه';
          case Apartments_Types.hut:
          return 'كوخ';
        default:
            return 'شقة';
       }
}
export default view_apartment_type_ar;