//                Validation

export const pattern = {
    "name_ar" :  /^[\u0600-\u06FF\u0750-\u077F]{2,100}( ){0,10}[\u0600-\u06FF\u0750-\u077F ]{0,100}$/,
    "name_en" : /^[A-Za-z]{2,100}(){0,10}[A-Za-z]{0,100}$/,
    "name_en_ar" : /[\u0600-\u06FF\u0750-\u077FA-Za-z]{2,100}( ){0,10}[A-Za-z\u0600-\u06FF\u0750-\u077F ]{0,100}/,
    "number_name_en" : /^[A-Za-z0-9]{1,10}$/,
    "number_int" : /^[0-9]{1,10}$/,
    "number_float" : /^[0-9]{1,10}(.){0,10}[0-9]{0,9}$/,
    "number_whatsapp" : /^[+]{1}[0-9]{5,14}$/,
    "phone_number" : /^[9]{1}[0-9]{8}$/,
    "position_n" : /^[0-9]{2}[.]{1}[0-9]{6}$/,//32.819729, 12.994366
    "position_e" : /^[0-9]{2}[.]{1}[0-9]{3,6}$/,//32.819729, 12.994366
    "website" : /^[a-z]{2,16}[.]{1}[a-z]{2,3}}$/,
    "email": /^[A-Za-z@.]{2,50}[A-Za-z.]{0,47}$/,
};
export default pattern;

// export const patnn_name_ar=/^[\u0600-\u06FF\u0750-\u077F]{2,50}( ){0,10}[\u0600-\u06FF\u0750-\u077F ]{0,47}$/;
// export const patnn_number_int=/^[0-9]{1,10}$/;
// export const patnn_position_n=/^[0-9]{2}[.]{1}[0-9]{6}$/;//32.819729, 12.994366
// export const patnn_position_e=/^[0-9]{2}[.]{1}[0-9]{3,6}$/;//32.819729, 12.994366
// export const patnn_number_float=/^[0-9]{1,10}(.){0,10}[0-9]{0,9}$/;