import Moment from 'moment';
const view_day_ar =  function(day)
{
    var day_en   = new Date(Moment(day).format("yyyy-MM-DD"));
    switch(Moment(day_en).format("dddd")) {
        case 'Saturday':
          return 'السبت';
        case 'Sunday':
            return 'الأحد';
        case 'Monday':
          return 'الإثنين';
        case 'Tuesday':
            return 'الثلاثاء';
        case 'Wednesday':
          return 'الأربعاء';
        case 'Thursday':
            return 'الخميس';
        case 'Friday':
            return 'الجمعة';
        default:
          return null
      }
}
export default view_day_ar;