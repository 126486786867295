import axios from "axios";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flip, ToastContainer } from 'react-toastify';
import { getcustomers } from '../Helper/links';

function Customers() {
    const Navigate = useNavigate();
    const [name_phone, setname_phone] = useState("");
    const [loading_customers,set_loading_customers]= useState(false);
    const [customers,set_customers]= useState([]);
    const [customers_view,set_customers_view]= useState([]);
    const clearFilter = () => {
        setname_phone("");
        GetCustomers();
    };
    const renderHeader = () => {
        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-11">
                        <div className="flex justify-content-between" style={{fontFamily:"Cairo",fontSize:"15px"}} dir="rtl">
                            <InputText
                                value={name_phone}
                                onChange={(e) => setname_phone(e.target.value)}
                                placeholder="الأسم أو رقم الهاتف"
                                className="col-md-4"
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                            <Button
                                dir="ltr"
                                type="button"
                                icon="fa fa-remove"
                                outlined
                                severity="danger"
                                onClick={clearFilter}
                                style={{marginRight:"0px"}}
                                />
                           <Button
                                dir="ltr"
                                type="button"
                                icon="fa fa-search"
                                outlined
                                onClick={search}
                                style={{marginRight:"5px"}}
                                />

                        </div>
                    </div>
                    <div className="col-md-1" style={{textAlign:"left",fontFamily:"Cairo"}}>
                        <Button icon="fa fa-refresh" rounded raised onClick={GetCustomers} />
                        <p style={{fontSize:"18px"}}> {customers_view && customers_view.length>=0 ? customers_view.length+" عميل":null}</p>

                    </div>

                </div>

          </div>
        );
    };
    const search = (e) => {
        e.preventDefault();
       //___________________________________________________________________
       //                  Search

        var resulte = []
        // eslint-disable-next-line array-callback-return
        customers.map((customer) => {
                if(
                  (
                    customer.visitor_name.startsWith(name_phone.toString())
                    || customer.visitor_name.endsWith(name_phone.toString())
                    //_____________________________________________
                    || (customer.phone_number.toString()).startsWith(name_phone.toString())
                    || (customer.phone_number.toString()).endsWith(name_phone.toString())
                    //_____________________________________________
                    || (customer.whatsapp_number!==null && (customer.whatsapp_number.toString()).startsWith(name_phone.toString()))
                    || (customer.whatsapp_number!==null && (customer.whatsapp_number.toString()).endsWith(name_phone.toString()))
                  )
            )
            {
                resulte.push(customer);
            }
        })
        set_customers_view(resulte);

    };
    //                      Get Customers
    const GetCustomers = async () =>
    {
        set_loading_customers(true);
        var response = await axios.post(getcustomers,{"TOKEN": window.localStorage.getItem('token'),"state":1});
        set_loading_customers(false);
        console.log(response)
        if (response.data.success === 500 || response.data.error === 1)
        {
            Navigate('/error', {
                state: {
                    msg: response.data.msg
                }
            })
        }
        else if(response.data.success===1)
        {
            set_customers(response.data.customers);
            set_customers_view(response.data.customers);
        }
    }
    useEffect(() =>
    {
        GetCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  return (
    <div className="page-wrapper" dir="rtl" >
        <div className="content-wrapper">
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Flip}
            />
             <div className="page-heading">
                <h1 className="page-title"> العملاء </h1>
            </div>
            <div className="card">
            <DataTable value={customers_view} stripedRows loading={loading_customers}
             header={renderHeader}
             emptyMessage={<center><h4>لا يوجد عملاء</h4></center>}
             scrollable scrollHeight="650px"
             tableStyle={{fontFamily:"Cairo",direction:"rtl",fontSize: "17px",border: "10px"}}
            >
                <Column field="visitor_name" header="الاسم" sortable style={{ textAlign:"right" }}></Column>
                <Column field="phone_number" header="رقم الهاتف" style={{ textAlign:"right" }}></Column>
                <Column field="whatsapp_number" header="رقم الواتساب" style={{ textAlign:"right" }}></Column>
            </DataTable>
        </div>

        </div>
    </div>
  )
}

export default Customers