const sum_total_price =  function(res)
{
  var sum=0
  for(var i=0;i<res.length;i++)
  {
    sum+=(res[i].total_price*1);
  }
  return sum;
}
export default sum_total_price;







