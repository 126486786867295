
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getbookings_checkin_today, getbookings_checkout_today, getbookingsnow, getdatadashboard } from "../Helper/links";
import { TOKEN } from "../Helper/user";
import view_apartment_type_ar from '../functions/view_apartment_type';
import view_day_ar from '../functions/view_day_ar';


let booking_apartments_dates = {};
function Home(e)
{

    const Navigate = useNavigate();
    const [dashbord, setDashbord] = useState({});
    const [bookingnow, setBookingnow] = useState([]);
    const [bookingntoday, setBookingtoday] = useState([]);
    const [CheckOutToday, setCheckOutToday] = useState([]);
    const GetInfoDashboard = async () =>
    {
        var response = await axios.post(getdatadashboard,{"TOKEN":TOKEN})
        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                if(response.data.success === 500 )
                    Navigate("/logout")
                else
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                setDashbord(response.data);
            }
        }

    }
    const GetReservationesNow = async () =>
    {
        var response = await axios.post(getbookingsnow,{"TOKEN": TOKEN,"del":0})
        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                if(response.data.success === 500 )
                    Navigate("/logout")
                else
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                setBookingnow(response.data.reservationes);
            }
        }


    }
    const GetReservationesCheckInToday = async () =>
    {
        var response = await axios.post(getbookings_checkin_today,{"TOKEN": TOKEN})
        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                if(response.data.success === 500 )
                    Navigate("/logout")
                else
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                setBookingtoday(response.data.reservationes_today);
            }
        }
    }
    const GetReservationesCheckOutToday = async () =>
    {
        var response = await axios.post(getbookings_checkout_today,{"TOKEN": TOKEN})
        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                if(response.data.success === 500 )
                    Navigate("/logout")
                else
                    Navigate('/error', {
                        state: {
                            msg: response.data.msg
                        }
                    })
            }
            else if(response.data.success===1)
            {
                setCheckOutToday(response.data.reservationes_today);
            }
        }
    }
    useEffect(() =>
    {
        GetInfoDashboard();
        GetReservationesNow();
        GetReservationesCheckInToday();
        GetReservationesCheckOutToday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
    <div className="page-wrapper">
        <div className="content-wrapper" dir='rtl'>
            <div className="page-content fade-in-up">
                <div className="row">
                    <div className="col-lg-3 col-md-6" >
                        <div className="ibox bg-success color-white widget-stat">
                            <div className="ibox-body">
                                <i className="fa fa-money widget-stat-icon"></i>
                                <h4 className="m-b-5 font-strong">الخزينة</h4>
                                <h4 className="m-b-5 font-strong">
                                    {
                                        dashbord.treasury ?
                                        (
                                            dashbord.treasury + " د.ل"
                                        )
                                        :
                                        (null)
                                    }
                                </h4>
                                <div>
                                    <i className="fa fa-level-up m-r-5"></i>
                                    {
                                        dashbord.count_payments ?
                                        (

                                                <small>{dashbord.count_payments} فاتورة </small>

                                        )
                                        :
                                        (null)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="ibox bg-warning color-white widget-stat">
                            <div className="ibox-body">
                                <i className="fa fa-money widget-stat-icon"></i>
                                <h4 className="m-b-5 font-strong">دفع</h4>
                                <h3 className="m-b-5 font-strong">
                                    {
                                        dashbord.total_amount_push ?
                                        (
                                            dashbord.total_amount_push + " د.ل"
                                        )
                                        :
                                        (null)
                                    }
                                </h3>
                                <div>
                                    <i className="fa fa-level-up m-r-5"></i>
                                {
                                    dashbord.count_payments_push ?
                                    (
                                        <small>{dashbord.count_payments_push} فاتورة </small>
                                    )
                                    :
                                    (null)
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="ibox bg-danger color-white widget-stat">
                            <div className="ibox-body">
                                <i className="fa fa-money widget-stat-icon"></i>
                                <h4 className="m-b-5 font-strong">استرداد</h4>
                                <h3 className="m-b-5 font-strong">
                                    {
                                        dashbord.total_amount_withdraw ?
                                        (
                                            dashbord.total_amount_withdraw + " د.ل"
                                        )
                                        :
                                        (null)
                                    }
                                </h3>
                                <div>
                                    <i className="fa fa-level-up m-r-5"></i>
                                    {
                                        dashbord.count_payments_withdraw ?
                                        (

                                                <small>{dashbord.count_payments_withdraw} فاتورة </small>

                                        )
                                        :
                                        (null)
                                    }
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="ibox bg-info color-white widget-stat">
                            <div className="ibox-body">
                                <i className="ti-bar-chart widget-stat-icon"></i>
                                <h4 className="m-b-5 font-strong">
                                    حجوزات جديدة
                                </h4>
                                <h4 className="m-b-5 font-strong">
                                    {
                                        dashbord.count_reservationes ?
                                        (
                                            dashbord.count_reservationes + " حجوزات"
                                        )
                                        :
                                        (null)
                                    }
                                </h4>
                                <div>
                                    <i className="fa fa-level-up m-r-5"></i>
                                    {
                                        dashbord.count_apartments ?
                                        (

                                                <small>{dashbord.count_apartments} شاليهات </small>

                                        )
                                        :
                                        (null)
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">أستقبال الحجوزات اليوم</div>
                                <button className="btn btn-info btn-sm" onClick={GetReservationesCheckInToday}>
                                    {moment(new Date()).format('yyyy-MM-DD')}
                                    <br></br>
                                    {view_day_ar(new Date())}
                                </button>
                            </div>
                            <div className="ibox-body" >
                                <ul className="list-group list-group-divider list-group-full tasks-list">

                                    {
                                        bookingntoday.map((res)=>
                                        {
                                            return(
                                            <li className="list-group-item task-item" key={res.ID_reservation}>
                                                <div>
                                                    <label className="ui-checkbox ui-checkbox-gray ui-checkbox-success">
                                                        <input type="checkbox"/>
                                                        <span className="input-span"></span>
                                                        <span className="task-title">{"الحجز ( "+ res.ID_reservation +" ) "}
                                                        {view_apartment_type_ar(res.type)+" - "+ res.number}
                                                        </span>
                                                    </label>
                                                    {
                                                        res.total_price - res.total_paid === 0 ?
                                                        (
                                                            <span className="badge badge-success m-l-5" style={{margin:"5px",fontSize:"15px"}}>
                                                                <i className="fa fa-money"></i> خالص
                                                            </span>
                                                        )
                                                        :
                                                        (
                                                            <span className="badge badge-danger m-l-5" style={{margin:"5px",fontSize:"15px"}}>
                                                                <i className="fa fa-money"></i> {res.total_price - res.total_paid} د.ل
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                                <div className="task-data" style={{fontSize:"15px"}} >

                                                    {res.visitor_name + " "}
                                                    <a href={"tel:+218"+res.phone_number}>{res.phone_number}</a>
                                                    {
                                                        res.phone_number !== res.whatsapp_number ?
                                                        (
                                                            <>
                                                            -
                                                            <a href={"tel:+"+res.whatsapp_number} >{res.whatsapp_number} </a>
                                                            </>


                                                        )
                                                        :
                                                        (null)
                                                    }
                                                </div>

                                            </li>
                                            );
                                        }
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">خروج الحجوزات اليوم</div>

                                <button className="btn btn-info btn-sm"  onClick={GetReservationesCheckOutToday}>
                                     {moment(new Date()).format('yyyy-MM-DD')}
                                    <br></br>
                                    {view_day_ar(new Date())}
                                </button>

                            </div>
                            <div className="ibox-body">
                                <ul className="list-group list-group-divider list-group-full tasks-list">
                                    {
                                        CheckOutToday.map((res)=>
                                        {
                                            return(
                                            <li className="list-group-item task-item">
                                                <div>
                                                    <label className="ui-checkbox ui-checkbox-gray ui-checkbox-success">
                                                        <input type="checkbox"/>
                                                        <span className="input-span"></span>
                                                        <span className="task-title">{"الحجز ( "+ res.ID_reservation +" ) "}{view_apartment_type_ar(res.type)+" - "+ res.number}</span>
                                                    </label>
                                                    {
                                                        res.total_price - res.total_paid === 0 ?
                                                        (
                                                            <span className="badge badge-success m-l-5" style={{margin:"5px",fontSize:"15px"}}>
                                                                <i className="fa fa-money"></i> خالص
                                                            </span>
                                                        )
                                                        :
                                                        (
                                                            <span className="badge badge-danger m-l-5" style={{margin:"5px",fontSize:"15px"}}>
                                                                <i className="fa fa-money"></i> {res.total_price - res.total_paid} د.ل
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                                <div className="task-data" style={{fontSize:"15px"}} >

                                                    {res.visitor_name + " "}
                                                    <a href={"tel:+218"+res.phone_number}>{res.phone_number}</a>
                                                    {
                                                        res.phone_number !== res.whatsapp_number ?
                                                        (
                                                            <>
                                                             -
                                                            <a href={"tel:+"+res.whatsapp_number} >{res.whatsapp_number} </a>
                                                            </>
                                                        )
                                                        :
                                                        (null)
                                                    }
                                                </div>

                                            </li>
                                            );
                                        }
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                  الحجوزات الحالية   ( {view_day_ar(new Date())}  {moment(new Date()).format('yyyy-MM-DD')} )
                                </div>
                            </div>
                            <div className="ibox-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>الحجز</th>
                                                <th>الزبون</th>
                                                <th>الشالية</th>
                                                <th colSpan={2}>دخول - الخروج</th>
                                                <th>السعر</th>
                                                <th>المدفوع</th>
                                                <th>الباقي</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bookingnow.map((res)=>
                                                {
                                                    return(
                                                    <tr>
                                                        <td>
                                                            {res.ID_reservation}
                                                        </td>
                                                        <td>
                                                            {res.visitor_name}
                                                            <br></br>
                                                            {res.phone_number}
                                                        </td>
                                                        <td>{view_apartment_type_ar(res.type) + "-" + res.number}</td>
                                                        <td>
                                                            {view_day_ar(res.date_check_in)}
                                                            <br></br>
                                                            {moment(res.date_check_in).format("MM-DD")}
                                                        </td>
                                                        <td>
                                                            {view_day_ar(res.date_check_out)}
                                                            <br></br>
                                                            {moment(res.date_check_out).format("MM-DD")}
                                                        </td>

                                                        <td>{res.total_price} د.ل</td>
                                                        <td style={{color:"green"}}>{res.total_paid} د.ل</td>
                                                        <td>
                                                            {
                                                                (res.total_price - res.total_paid) === 0 ?
                                                                (
                                                                    <span className="badge badge-success">
                                                                        خالص
                                                                    </span>
                                                                )
                                                                :
                                                                (
                                                                    <span className="badge badge-danger">
                                                                    {res.total_price - res.total_paid} د،ل
                                                                    </span>
                                                                )
                                                            }

                                                        </td>
                                                    </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <style>
                    .visitors-table tbody tr td:last-child {
                        display: flex;
                        align-items: center;
                    }

                    .visitors-table .progress {
                        flex: 1;
                    }

                    .visitors-table .progress-parcent {
                        text-align: right;
                        margin-left: 10px;
                    }
                </style> */}
            </div>

            <footer className="page-footer" >
                <div className="font-13">{moment(new Date()).format("YYYY")} ©  جميع الحقوق محفوظة  - <b>YourSummer v1.0.1 </b> </div>
                <a className="px-4" href="https://github.com/hamzahamruni" target="_blank" rel="noreferrer"> Hamza Hamruni <i className="fa fa-github"></i></a>
                <div className="to-top"><i className="fa fa-angle-double-up"></i></div>
            </footer>
        </div>
    </div>
    )
}

export default Home


