
import React from "react";
import { useNavigate } from "react-router-dom";


import { Link } from 'react-router-dom';
import { linkLogosResort } from './links';
import { RESORT_LOGO_PATH, RESORT_NAME_EN, SHORT_RESORT_NAME } from './user';

const USERNAME =   window.localStorage.getItem('username');
const NAME =   window.localStorage.getItem('name');

function Sidebar()
{

    const Navigate = useNavigate();


    const logOutUser = (e) =>
    {
        e.preventDefault();
        Navigate("/logout")
    }

    return (
    <>

        <header className="header" >
            <div className="page-brand">


                    <span className="brand" >{RESORT_NAME_EN}
                        {/* <span className="brand-tip"></span> */}
                    </span>
                    <span className="brand-mini" >{SHORT_RESORT_NAME}</span>


            </div>
            <div className="flexbox flex-1">
                <ul className="nav navbar-toolbar">
                    <li>
                        <a className="nav-link sidebar-toggler js-sidebar-toggler" ><i className="ti-menu"></i></a>
                    </li>
                </ul>

                <ul className="nav navbar-toolbar">
                    {/* <li className="dropdown dropdown-notification">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown"><i className="fa fa-bell-o rel"><span className="notify-signal"></span></i></a>
                        <ul className="dropdown-menu dropdown-menu-right dropdown-menu-media">
                            <li className="dropdown-menu-header">
                                <div>
                                    <span><strong>5 New</strong> Notifications</span>
                                    <a className="pull-right" href="javascript:;">view all</a>
                                </div>
                            </li>
                            <li className="list-group list-group-divider scroller" data-height="240px" data-color="#71808f">
                                <div>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-success badge-big"><i className="fa fa-check"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">4 task compiled</div><small className="text-muted">22 mins</small></div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-default badge-big"><i className="fa fa-shopping-basket"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">You have 12 new orders</div><small className="text-muted">40 mins</small></div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-danger badge-big"><i className="fa fa-bolt"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">Server #7 rebooted</div><small className="text-muted">2 hrs</small></div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-success badge-big"><i className="fa fa-user"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">New user registered</div><small className="text-muted">2 hrs</small></div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </li> */}
                    <li className="dropdown dropdown-user">
                        <a className="nav-link dropdown-toggle link" data-toggle="dropdown" href='#user'>
                            <img  src={linkLogosResort+RESORT_LOGO_PATH}  width="60px" height="40px"   alt='user' />
                            <span></span>
                            {USERNAME}  - {NAME} <i className="fa fa-angle-down m-l-5"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" style={{textAlign:"center"}}>
                            <a className="dropdown-item" onClick={(e)=> logOutUser(e)} href='#user'><i className="fa fa-sign-out"></i> تسجيل خروج  </a>
                        </ul>
                    </li>
                </ul>
            </div>
        </header>

        <nav className="page-sidebar" id="sidebar">
            <div id="sidebar-collapse">
                <div className="admin-block d-flex">
                    <center>
                        <img src="./assets/img/logo.png" alt='logo' width="50%" />
                    </center>
                </div>
                <ul className="side-menu metismenu">
                    <li>
                        <Link to="/home" >
                            <i className="sidebar-item-icon fa fa-th-large"></i>
                            <span className="nav-label">الرئسية</span>
                        </Link>

                    </li>


                    <hr style={{backgroundColor:"rgba(255, 255, 255, .4)",width:"90%",textAlign:"center"}}></hr>
                        <li>
                            <a href="#revs"><i className="sidebar-item-icon fa fa-calendar"></i>
                                <span className="nav-label">إدارة الحجز</span>
                            </a>
                            <ul className="nav-2-level collapse">
                                <li>
                                    <Link to="/booking-new" >
                                        <span className="nav-label">حجز جديد</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/bookings" >
                                        <span className="nav-label">حجوزات</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <hr style={{backgroundColor:"rgba(255, 255, 255, .4)",width:"90%",textAlign:"center"}}></hr>
                        <li>
                            <a href="#rev"><i className="sidebar-item-icon fa fa-users"></i>
                                <span className="nav-label">إدارة العملاء</span>
                            </a>
                            <ul className="nav-2-level collapse">
                                <li>
                                    <Link to="/customer-new" >
                                        <span className="nav-label">عميل جديد</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/customers" >
                                        <span className="nav-label">عملاء</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <hr style={{backgroundColor:"rgba(255, 255, 255, .4)",width:"90%",textAlign:"center"}}></hr>
                        <li>
                            <a href="#pay"><i className="sidebar-item-icon fa fa-money"></i>
                                <span className="nav-label">إدارة المالية</span>
                            </a>
                            <ul className="nav-2-level collapse">
                            <li>
                                <Link to="/payments" >
                                    <span className="nav-label">المدفوعات</span>
                                </Link>
                            </li>
                            </ul>
                        </li>
                        <hr style={{backgroundColor:"rgba(255, 255, 255, .4)",width:"90%",textAlign:"center"}}></hr>
                        <li>
                            <a href="#reports"><i className="sidebar-item-icon fa fa-money"></i>
                                <span className="nav-label">إدارة التقارير</span>
                            </a>
                            <ul className="nav-2-level collapse">
                            <li>
                                <Link to="/report_bookinges" >
                                    <span className="nav-label">تقرير الحجوزات</span>
                                </Link>
                            </li>
                            </ul>
                        </li>
                </ul>
            </div>
        </nav>


        {/* <div className="content-wrapper">
            <div className="page-content fade-in-up">



            </div>
        </div> */}

    </>

    )
}

export default Sidebar


