
//_______________________________________________________________________________
//                          Link Server
export const linksytemweb="https://booking.system.yoursummer.ly";// Server
// export const linksytemweb="http://localhost:3000";// DEMO

export const baseUrl = "https://system.yoursummer.ly/web/API/";// Server
// export const baseUrl = "http://127.0.0.1/App/Booking-System/Server/web/API/";// DEMO
export const linkLogin = baseUrl+"logs/sign-in.php";
//_______________________________________________________________________________
//                          Resorts
export const linkSetResort = baseUrl+"resorts/set_resort.php";
export const linkGetResorts = baseUrl+"resorts/get_resorts.php";
export const linkLogosResort = baseUrl+"resorts/images/";
//_______________________________________________________________________________
//                          Apartments
export const getimagesapartment = baseUrl+"apartments/images/";
export const setapartment = baseUrl+"apartments/set_apartment.php";
export const getapartment = baseUrl+"apartments/get_apartments.php";
export const getapartments_resort = baseUrl+"apartments/get_apartments_resort.php";
export const getapartments_dates_resort = baseUrl+"apartments/get_apartments_dates_resort.php";
export const getapartment_dates_resort = baseUrl+"apartments/get_apartment_dates_resort.php";
//_______________________________________________________________________________
//                          Customers
export const setcustomer = baseUrl+"customers/set_customer.php";
export const getcustomers  = baseUrl+"customers/get_customers.php?state=1";
//_______________________________________________________________________________
//                          Booking
export const setbooking = baseUrl+"reservationes/set_reservation.php";
export const getbookings  = baseUrl+"reservationes/get_reservationes.php";
export const getbookingsnow  = baseUrl+"reservationes/get_reservationes_now.php";
export const getbookingscoming  = baseUrl+"reservationes/get_reservationes_coming.php";
export const getbookingsold  = baseUrl+"reservationes/get_reservationes_old.php";
export const getbookingsdel  = baseUrl+"reservationes/get_reservationes_del.php";

export const getsearchbookings  = baseUrl+"reservationes/get_search_reservationes.php";
export const editbooking  = baseUrl+"reservationes/edit_reservation.php";
//_______________________________________________________________________________
//                         Paid Booking
export const setpaidbooking = baseUrl+"reservationes/paid_reservationes.php";
export const setdeletebooking = baseUrl+"reservationes/delete_reservationes.php";
//_______________________________________________________________________________
//                          Payments
export const getpaymentsdate = baseUrl+"reservationes/payments/get_payments_day.php";
export const getpaymentsmonth = baseUrl+"reservationes/payments/get_payments_month.php";
export const getpaymentsyear = baseUrl+"reservationes/payments/get_payments_year.php";

//_______________________________________________________________________________
//                          Dashboard
export const getdatadashboard = baseUrl+"home/data_dahsboard.php";
export const getbookings_checkin_today = baseUrl+"reservationes/get_reservationes_check_in_today.php";
export const getbookings_checkout_today = baseUrl+"reservationes/get_reservationes_check_out_today.php";
//_______________________________________________________________________________
//                          Users
export const getallusers = baseUrl+"users/get_all_users_resort.php";
//_______________________________________________________________________________
//                          Link Whatsapp Msg Auto
export const UrlًWhatsappMsgInvoice = baseUrl+"auto/msg_whatsapp.php";// Server
export const UrlًInvoicePdf = 'https://system.yoursummer.ly/invoice_pdf/public/index.php';// SERVER
// export const UrlًInvoicePdf = 'http://localhost/App/Booking-System/Server/invoice_pdf/public/index.php';// DEMO
