
import moment from 'moment';
import { Button } from 'primereact/button';
import React, { useContext, useRef, useState } from 'react';
import QRCode from "react-qr-code";
import ReactToPrint from 'react-to-print';
import { linkLogosResort } from "../../Helper/links";
import { NAME, RESORT_EMAIL, RESORT_LOGO_PATH, RESORT_NAME_AR, RESORT_NAME_EN, RESORT_PHONE_1, RESORT_WEBSITE } from '../../Helper/user';
import count_days from '../../functions/count_days';
import sum_total_paid from '../../functions/sum_total_paid';
import sum_total_price from '../../functions/sum_total_price';
import view_apartment_type_ar from '../../functions/view_apartment_type';
import view_day_ar from '../../functions/view_day_ar';
import apple_stor from '../../public/images/apple_stor.svg';
import google_play from '../../public/images/google_play.png';
import logo from '../../public/images/logo.png';
import { InvoiceContext } from './Invoice_Context';
import './invoice.css';

function InvoiceCustomer()
{
    const data = useContext(InvoiceContext)
    const componentRef = useRef();
    const buttonRef = useRef();
    // eslint-disable-next-line no-unused-vars
    const [isShown, setIsShown] = useState(false);
    return (
    <div>
        <div id="invoice" className="page-content fade-in-up" dir="rtl" style={{margin:"auto",maxWidth:"850PX"}} ref={componentRef}>
            <div className="ibox invoice-print">
                <div className="invoice-header">

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3">
                                    <div className="col-12 text-right" style={{paddingTop:"50px"}}>
                                        <div className="clf" >
                                            <dl className="row pull-right" >
                                                <dt className="col-sm-12">المكتب الحجز</dt>
                                                <dd className="col-sm-12" style={{paddingRight:"15px",margin:"0"}}>طرابلس - غرور</dd>
                                                <div style={{ height: "auto", marginTop:"2px",paddingRight:"15px", maxWidth: 70, width: "100%" }}>
                                                <QRCode
                                                    size={200}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={"https://"+RESORT_WEBSITE}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                </div>
                                            </dl>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={linkLogosResort +RESORT_LOGO_PATH} alt='logo' height="100px" width="250px" />
                                            <h5>فاتورة الحجوزات</h5>
                                        </div>
                                    </center>
                                </div>

                                <div className="col-3">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={logo} height="100px" width="100px"  alt='logo'/>
                                            <br></br>
                                            <img src={apple_stor} height="26px"  alt='logo' style={{marginTop:"5px"}}/>
                                            <br></br>
                                            <img src={google_play} height="35px"   alt='logo'/>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6" style={{borderLeft:"1px solid rgba(0,0,0,.1"}}>
                                        <div className="m-b-5 font-bold">بيانات الزبون</div>
                                        <ul className="list-unstyled m-t-10">
                                            <li className="m-b-5">
                                                <span className="font-strong"> الأسم  :</span> {data.customer.visitor_name} </li>
                                            <li className="m-b-5">
                                                <span className="font-strong"> الهاتف :</span> {data.customer.phone_number}</li>
                                        </ul>
                                    </div>

                                    <div className="col-6"  style={{borderRight:"1px solid rgba(0,0,0,.1"}}>
                                        <div className="m-b-5 font-bold">بيانات الحجز</div>
                                            <ul className="list-unstyled m-t-10">
                                            <li className="m-b-5">
                                                <span className="font-strong"> عدد الحجوزات :</span> {data.customer.reservationes.length} </li>
                                            <li className="m-b-5">
                                            <span className="font-strong"> عدد الأيام :</span> {count_days(data.customer.reservationes)}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                        </div>
                    </div>
                </div>
                <table className="table table-bordered table-striped no-margin table-invoice">
                    <thead className="color-view ">
                        <tr >
                            <th>الحجز</th>
                            <th>الشالية</th>
                            <th>الأيام</th>
                            <th colSpan={2} style={{textAlign:"center"}} >
                                الدخول - الخروج
                            </th>
                            <th>السعر</th>
                            <th>الإجمالي</th>
                            <th>المدفوع</th>
                            <th>الباقي</th>
                            <th style={{textAlign:"center",width:"10%"}}>تاريخ  إلغاء </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.customer.reservationes.map((res)=>{
                                return(
                                <tr>
                                    <td>{res.ID_reservation}</td>
                                    <td>
                                        <div>
                                        <small>{view_apartment_type_ar(res.type)}</small><strong>{"-"+ res.number}</strong>
                                        </div>
                                        <small>{res.number_people} أشخاص </small>
                                    </td>
                                    <td>{count_days([res])} أيام</td>
                                    <td>
                                        <div>
                                            <strong>{res.date_check_in} </strong>
                                        </div>
                                        <small> {view_day_ar(res.date_check_in)} </small>
                                    </td>
                                    <td>
                                        <div>
                                            <strong>{res.date_check_out}  </strong>
                                        </div>
                                        <small>  {view_day_ar(res.date_check_out)} </small>
                                    </td>
                                    <td>{res.price_day}  د.ل</td>
                                    <td>{res.total_price} د.ل</td>
                                    <td>{res.total_paid} د.ل</td>
                                    <td>
                                            {
                                                res.total_price-res.total_paid ===0 ?
                                                (
                                                    <strong style={{color:"green"}}>
                                                        خالص
                                                    </strong>
                                                )
                                                :
                                                (
                                                    <strong style={{color:"red"}}>
                                                    {res.total_price-res.total_paid} د.ل
                                                    </strong>
                                                )
                                            }
                                    </td>
                                    <td >
                                        {
                                            res.date_del_reservation?
                                            <strong style={{color:"red"}}>
                                                {moment(res.date_del_reservation).format('MM-DD')}
                                                <br></br>
                                                {moment(res.date_del_reservation).format('LT')}
                                            </strong>
                                            :"-"
                                        }
                                    </td>
                                </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <table className="table no-border">
                    <thead>
                    {/* <th width="15%"></th> */}
                        {
                            data.total_amount ?
                            (
                                <tr className="text-center" >
                                    <td  className="font-bold font-15 text-left" > القيمة المدفوعة </td>
                                    <td className="font-bold font-15 text-right">{data.total_amount} د.ل</td>
                                </tr>
                            )
                            :
                            (null)
                        }
                        <tr>
                            <td className="font-bold font-18 text-center">إجمالي المبلغ  </td>
                            <td className="font-bold font-18 text-center">إجمالي المدفوع  </td>
                            <td className="font-bold font-18 text-center">إجمالي المتبقي  </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td  className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_price(data.customer.reservationes)}
                                    د.ل</td>
                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_paid(data.customer.reservationes)} د.ل</td>

                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>
                                {
                                    sum_total_price(data.customer.reservationes)-sum_total_paid(data.customer.reservationes) ===0 ?
                                    (
                                        <strong style={{color:"green"}}>
                                            خالص
                                        </strong>
                                    )
                                    :
                                    (
                                        <strong style={{color:"red"}}>
                                            {sum_total_price(data.customer.reservationes)-sum_total_paid(data.customer.reservationes)} د.ل
                                        </strong>
                                    )
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          <hr/>
          <footer >
          <table className="table no-border">
                    <thead>
                        <tr>
                            <th className="font-bold font-18 text-center" colSpan={10}>
                                <center>
                                نتمني لكم قضاء صيف رائع في {RESORT_NAME_AR} - {RESORT_NAME_EN}
                                </center>

                            </th>
                        </tr>

                        <tr>
                            <th>
                                <div className="font-13">
                                <b> {RESORT_NAME_AR} - {RESORT_NAME_EN}  </b> <i className='fa fa-flag'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b >  {RESORT_PHONE_1} </b>  <i className='fa fa-phone'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > www.{RESORT_WEBSITE}  </b> <i className='fa fa-globe'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > {RESORT_EMAIL} </b> <i className="fa fa-envelope"></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > {NAME}  </b> <i className='fa fa-user'></i>
                                </div>
                            </th>

                        </tr>
                    </thead>
            </table>
          </footer>
            <hr/>
        </div>

        <div className="text-center">
            <ReactToPrint
                trigger={
                    () =>  <Button dir='ltr' type="button"  label=" طبــاعة "  icon='fa fa-print' raised
                    ref={buttonRef}  onClick={(e)=> setIsShown(current => !current)}
                    />
                }
                content={() => componentRef.current}
            />
        </div>

    </div>
    );
}
export default InvoiceCustomer;