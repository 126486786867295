import Moment from 'moment';
const date_delelete_reservation =  function(date_check_in)
{

      var diffInMs   = new Date(Moment(date_check_in).format("yyyy-MM-DD")) - new Date(Moment(new Date()).format("yyyy-MM-DD"));
      var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      if(diffInDays<1)
      {
       return Moment(new Date(new Date().setHours(new Date().getHours() + 1))).format("YYYY-MM-DD h:m A")
      }
      else if(diffInDays<20)
      {
        return Moment(new Date(new Date().setHours(new Date().getHours() + diffInDays))).format("YYYY-MM-DD h:m A")

      }
      else
      {
        return Moment(new Date(new Date().setDate(new Date().getDate() + 5))).format("YYYY-MM-DD h:m A")

      }
}
export default date_delelete_reservation;
