
import { Button } from 'primereact/button';
import React, { useContext } from 'react';
import QRCode from "react-qr-code";
import { linkLogosResort } from "../../Helper/links";
import { NAME, RESORT_EMAIL, RESORT_LOGO_PATH, RESORT_NAME_AR, RESORT_NAME_EN, RESORT_PHONE_1, RESORT_WEBSITE } from '../../Helper/user';
import count_days from '../../functions/count_days';
import sum_total_paid from '../../functions/sum_total_paid';
import sum_total_price from '../../functions/sum_total_price';
import view_apartment_type_ar from '../../functions/view_apartment_type';
import view_day_ar from '../../functions/view_day_ar';
import apple_stor from '../../public/images/apple_stor.svg';
import google_play from '../../public/images/google_play.png';
import logo from '../../public/images/logo.png';
import { InvoiceContext } from './Invoice_Context';
import './invoice.css';

let today = new Date();
let day = today.getDate();
let month = today.getMonth();
let year = today.getFullYear();
function InvoiceCustomerDelete()
{
    const data = useContext(InvoiceContext)
    function Print () {
       let printInvoice = document.getElementById('invoice').innerHTML;
       var mywindow = window.open('', '', 'height=1000,width=1000');
       mywindow.document.write('<html><head><title>Print Invoice </title>');
       mywindow.document.write('<link href="./assets/vendors/bootstrap/dist/css/bootstrap.min.css" rel="stylesheet" />');
       mywindow.document.write('<link href="./assets/vendors/font-awesome/css/font-awesome.min.css" rel="stylesheet" />');
       mywindow.document.write('<link href="./assets/vendors/themify-icons/css/themify-icons.css" rel="stylesheet" />');
       mywindow.document.write('<link href="./assets/css/main.min.css" rel="stylesheet" />');
       mywindow.document.write('<link href="./assets/css/invoice.css" rel="stylesheet" />');
       mywindow.document.write('<link rel="stylesheet" href="./assets/css/themes/jquery.fancybox.css" />');
       mywindow.document.write('</head><body dir="rtl">');
       mywindow.document.write(printInvoice);
       mywindow.document.write('</body></html>');
       mywindow.document.close();
        window.onload = setInterval(function(){
             mywindow.focus();
            mywindow.print();
             mywindow.close();
        }, 3000);
    };
    var aptview = null;
    return (
    <div>
        <div id="invoice" className="page-content fade-in-up" dir="rtl" style={{margin:"auto",maxWidth:"850PX"}}>
            <div className="ibox invoice-print">
                <div className="invoice-header">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3">
                                    <div className="col-12 text-right" style={{paddingTop:"50px"}}>
                                        <div className="clf" >
                                            <dl className="row pull-right" >
                                                <dt className="col-sm-12">المكتب الحجز</dt>
                                                <dd className="col-sm-12" style={{paddingRight:"15px",margin:"0"}}>طرابلس - غرور</dd>
                                                <div style={{ height: "auto", marginTop:"2px",paddingRight:"15px", maxWidth: 70, width: "100%" }}>
                                                <QRCode
                                                    size={200}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={"https://"+RESORT_WEBSITE}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                </div>
                                            </dl>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={linkLogosResort + RESORT_LOGO_PATH} height="100px" width="250px" alt='logo' />
                                            <h5 style={{color:"red"}}>فاتورة الحجوزات الملغية</h5>
                                        </div>
                                    </center>
                                </div>

                                <div className="col-3">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={logo} height="100px" width="100px"  alt='logo'/>
                                            <br></br>
                                            <img src={apple_stor} height="26px"  alt='logo' style={{marginTop:"5px"}}/>
                                            <br></br>
                                            <img src={google_play} height="35px"   alt='logo'/>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6" style={{borderLeft:"1px solid rgba(0,0,0,.1"}}>
                                        <div className="m-b-5 font-bold">بيانات الزبون</div>
                                        <ul className="list-unstyled m-t-10">
                                            <li className="m-b-5">
                                                <span className="font-strong"> الأسم  :</span> {data.customer.visitor_name} </li>
                                            <li className="m-b-5">
                                                <span className="font-strong"> الهاتف :</span> {data.customer.phone_number}</li>
                                        </ul>
                                    </div>

                                    <div className="col-6"  style={{borderRight:"1px solid rgba(0,0,0,.1"}}>
                                        <div className="m-b-5 font-bold">بيانات الحجز</div>
                                            <ul className="list-unstyled m-t-10">
                                            <li className="m-b-5">
                                                <span className="font-strong"> عدد الحجوزات :</span> {data.customer.reservationes.length} </li>
                                            <li className="m-b-5">
                                            <span className="font-strong"> عدد الأيام :</span> {count_days(data)}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="col-12">
                                <div className="row">
                                    <div className='col-12'>
                                        <li className="m-b-5">
                                            <span className="font-strong">  الشاليهات : </span>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                data.customer.reservationes.map((res)=>{
                                                    if(aptview !== res.number)
                                                    {
                                                        return(
                                                            <>
                                                            {res.type}
                                                            -
                                                            {aptview = res.number}
                                                            ,
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-striped no-margin table-invoice">
                    <thead className="color-view bg-ebony">
                        <tr style={{color:"white"}}>
                            <th>الحجز</th>
                            <th>الشالية</th>
                            <th>الأيام</th>
                            <th colSpan={2} style={{textAlign:"center"}} >
                             الدخول - الخروج
                            </th>
                            <th>السعر</th>
                            <th>الإجمالي</th>
                            <th>المدفوع</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.customer.reservationes.map((res)=>{
                                return(
                                <tr>
                                    <td>{res.ID_reservation}</td>
                                    <td>
                                        <div>
                                        <small>{view_apartment_type_ar(res.type)}</small><strong>{"-"+ res.number}</strong>
                                        </div>
                                        <small>{res.number_people} أشخاص </small>
                                    </td>
                                    <td>{count_days([res])} أيام</td>
                                    <td style={{color:"green"}}>
                                        <div>
                                            <strong>{res.date_check_in} </strong>
                                        </div>
                                        <small> {view_day_ar(res.date_check_in)} </small>
                                    </td>
                                    <td style={{color:"green"}}>
                                        <div>
                                            <strong>{res.date_check_out}  </strong>
                                        </div>
                                        <small>  {view_day_ar(res.date_check_out)} </small>
                                    </td>
                                    <td>{res.price_day}  د.ل</td>
                                    <td>{res.total_price} د.ل</td>
                                    <td>{res.total_paid} د.ل</td>
                                </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <table className="table no-border">
                    <thead>
                    {/* <th width="15%"></th> */}
                    {
                        data.total_amount ?
                        (
                            <tr className="text-center" >
                                <td  className="font-bold font-15 text-left" > القيمة المسترجعة </td>
                                <td className="font-bold font-15 text-right">{data.total_amount} د.ل</td>
                            </tr>
                        )
                        :
                        (null)
                    }

                        <tr>
                            <td className="font-bold font-18 text-center">إجمالي المبلغ  </td>
                            <td className="font-bold font-18 text-center">إجمالي المدفوع  </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td  className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_price(data.customer.reservationes)}
                                 د.ل</td>
                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_paid(data.customer.reservationes)} د.ل</td>
                        </tr>
                    </tbody>
                </table>
                <hr/>
                <table className="table no-border">
                        <thead>
                            <tr>
                                <th colSpan={10}>
                                    <center>
                                    نتمني لكم قضاء صيف رائع في {RESORT_NAME_AR} - {RESORT_NAME_EN}
                                    </center>

                                </th>
                            </tr>

                            <tr>
                                <th>
                                    <div className="font-13">
                                    <b> {RESORT_NAME_AR} - {RESORT_NAME_EN}  </b> <i className='fa fa-flag'></i>
                                    </div>
                                </th>
                                <th>
                                    <div className="font-13">
                                    <b >  {RESORT_PHONE_1}   </b>  <i className='fa fa-phone'></i>
                                    </div>
                                </th>
                                <th>
                                    <div className="font-13">
                                    <b > www.{RESORT_WEBSITE}  </b> <i className='fa fa-globe'></i>
                                    </div>
                                </th>
                                <th>
                                    <div className="font-13">
                                    <b > {RESORT_EMAIL} </b> <i className="fa fa-envelope"></i>
                                    </div>
                                </th>
                                <th>
                                    <div className="font-13">
                                    <b > {NAME}  </b> <i className='fa fa-user'></i>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                </table>
                <hr/>
            </div>
        </div>
        <div className="text-center">
            <Button  type="button" id="print" label=' طبــاعة '
              style={{fontFamily:"Cairo",marginRight:"10px",direction:"ltr"}}
              icon='fa fa-print'
             onClick={Print} autoFocus
            />
        </div>
    </div>
    );
}
export default InvoiceCustomerDelete;