
  import Moment from 'moment';
import count_days from './count_days';
const before_days =  function(date)
{
    var days = count_days([{
        date_check_in: date,
        date_check_out: new Date()
    }])
    if (days>30) {
        return Moment(date).format("YYYY-MM-DD");
      } else {
        switch (days) {
          case 0:
            return "اليوم ";
          case 1:
            return "امس";
          default:
            return "قبل "+days +" يوم";
        }
      }
}
export default before_days;