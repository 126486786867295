import React from 'react';
import { useLocation } from 'react-router-dom';

import { linksytemweb } from '../Helper/links';
import './error.css';
function Error() {
    const {state} = useLocation();
    return (
        <div className="content p-t-20" >
            <img className="img-fluid" src="https://yoursummer.ly//logo.png" alt="" width="90%"  />
            <hr></hr>
            <h1 className="m-t-20 ">404</h1>
            <p className="error-title m-t-0">
            الصفحة غير موجودة !
            </p>
            <hr></hr>
            <p className="error-title p-t-10">
                {
                    state !== null?
                    (<>{state.msg}<br></br></>)
                    :
                    (null)
                }
                <a className="text-info" href={linksytemweb}> انتقل إلي الصفحة الرئسية </a>
            </p>
        </div>
  );
}
export default Error