import Moment from 'moment';
const after_date_delelete_reservation =  function(date_check_in)
{
      var diffInMs   = new Date(Moment(date_check_in).format("yyyy-MM-DD")) - new Date(Moment(new Date()).format("yyyy-MM-DD"));
      var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      if(diffInDays<1)
      {
       return 'بعـد سـاعة';
      }
      else if(diffInDays<20)
      {
        return 'بعـد '+diffInDays+' سـاعات';
      }
      else
      {
        return 'بعـد 5 آيــام';

      }
}
export default after_date_delelete_reservation;