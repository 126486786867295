
const sum_total_paid =  function(res)
{
    var sum=0
    for(var i=0;i<res.length;i++)
    {
      sum+=(res[i].total_paid*1);
    }
    return sum;
}
export default sum_total_paid;