
const sum_amount =  function(res)
{
    var sum=0
    for(var i=0;i<res.length;i++)
    {
      sum+=(res[i].amount*1);
    }
    return sum;
}
export default sum_amount;