
import axios from "axios";
import { default as Moment, default as moment } from 'moment';
import { NULL } from "mysql/lib/protocol/constants/types.js";
import { addLocale } from 'primereact/api';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Helper/links';
import { UrlًInvoicePdf, UrlًWhatsappMsgInvoice, getapartments_dates_resort, getcustomers, getimagesapartment, setbooking, setpaidbooking } from "../Helper/links";
import pattern from "../Helper/pattern";
import after_date_delelete_reservation from "../functions/after_date_delelete_reservation.js";
import date_delelete_reservation from "../functions/date_delelete_reservation.js";
import sum_total_paid from '../functions/sum_total_paid';
import sum_total_price from '../functions/sum_total_price';
import view_apartment_type_ar from "../functions/view_apartment_type.js";
import '../public/themes/saga-blue/theme.css';
import { InvoiceContext } from './invoice/Invoice_Context';
import Invoice from './invoice/invoice_customer';
import PaidInvoice from './invoice/paid_Invoice';


let today = new Date();
// let day = today.getDate();
// let month = today.getMonth();
let booking_apartments_dates = {};
function BookingNew()
{
   // cd0117df@summerland.ly 8ndQF$hHv@aD
   addLocale('en', {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']    ,
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear'
  });
  addLocale('ar', {
    firstDayOfWeek: 6,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
    monthNames: ['January-01', 'February-02', 'March-03', 'April-04', 'May-05', 'June-06', 'July-07', 'August-08', 'September-09', 'October-10', 'November-11', 'December-12']    ,
    monthNamesShort: ['Jan-01', 'Feb-02', 'Mar-03', 'Apr-04', 'May-05', 'Jun-06', 'Jul-07', 'Aug-08', 'Sep-09', 'Oct-10', 'Nov-11', 'Dec-12'],
    today: 'اليوم',
    clear: 'تفريغ',
  });
    const Navigate = useNavigate();
    //_______________________________________________________
    const [loading_booking,set_loading_booking]= useState(false);
    const [loading_paid,set_loading_paid]= useState(false);
    //_______________________________________________________
    const [apartments, setApartments] = useState([]);
    const [customers, setCustomers]= useState([]);
    //_______________________________________________________
    const [id_apartment, setid_apartments] = useState("");
    const [apartment, set_apartment] = useState({});
    const [id_customer, setid_customer]= useState([]);
    const [visitor_name, setvisitor_name] = useState("");
    const [phone_number, setphone_number] = useState("");
    const [whatsapp_number, setwhatsapp_number] = useState(null);
    const [notes, setnote] = useState("");
    //_______________________________________________________
    const [del_reservation, set_del_reservation] = useState(1);
    const [paid, setpaid] = useState(0);
   //_______________________________________________________
    const [visible, setVisible] = useState(false);
    const [visibleBooking, setVisibleBooking] = useState(false);

    const [Book, setBook] = useState(null);
    const [customer_reservationes, setcustomer_reservationes] = useState({});
    //_______________________________________________________
    //                      Dates Apartments
    const [dates, setDates] = useState([]);
    var booking_dates = [];
    //_______________________________________________________
    //                      Dates Apartments
    const [ViewInvoice, setViewInvoice] = useState(false);
    //_______________________________________________________
    function dates_close(dates_reservationes)
    {
      var dates_disabled = [];
      dates_reservationes.map((date)=>{
        var date_in = new Date(date.date_check_in);
        var date_out = new Date(date.date_check_out);
        while(Moment(date_in).format("yyyy-MM-DD") < Moment(date_out).format("yyyy-MM-DD"))
        {
          dates_disabled.push(new Date(Moment(date_in).format("yyyy-MM-DD")));
          date_in.setDate(date_in.getDate() + 1);
        }
      })
      return dates_disabled;
    }
    function dateTemplate  (date,dates_reservationes)
    {
      var day_test = new Date(date.year + "-" + (date.month+1)+"-" + date.day);
        for(var i=0;i<dates_reservationes.length;i++)
        {
          if(
            Moment(day_test).format("yyyy-MM-DD") >=  Moment(dates_reservationes[i].date_check_in).format("yyyy-MM-DD")
            &&
            Moment(day_test).format("yyyy-MM-DD") <  Moment(dates_reservationes[i].date_check_out).format("yyyy-MM-DD")
          )
          {
            return (
              <strong style={{ textDecoration: 'line-through' , color:"red"}}>{date.day}</strong>
            );
          }

        }
        return (
          <strong style={{color:"black"}}>{date.day}</strong>
        );
    }
    const handel_setVisibleBooking = async (e)=>{
      setBook(null)
      setVisibleBooking(false);
    }
    const handelDates =  (e,id_apartment) =>
    {
      setDates(e.target.value);
      booking_apartments_dates[id_apartment]=e.target.value;
    }
    const handelBooking =  (e,apartment,id_apartment) =>
    {
      if(booking_apartments_dates[id_apartment] && booking_apartments_dates[id_apartment].length>0)
      {
        setDates(booking_apartments_dates[id_apartment]);
        setid_apartments(id_apartment);
        set_apartment(apartment);
        setid_customer("");
        setvisitor_name("")
        setphone_number("");
        setwhatsapp_number(null);
        setVisible(true);
      }
      else
      {
        toast.error('ارجو اختيار تواريخ الحجز', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }

    }
    //_______________________________________________________
    //                      Customers
    const [filtered_customers, set_filtered_customers] = useState(null);
    const search_customer_by_name = (event) => {
      //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
      let query = event.query;
      let _filtered_customers = [];
      for(let i = 0; i < customers.length; i++) {
          let customer = customers[i];
          if (customer.visitor_name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            _filtered_customers.push(customer);
          }
      }
      set_filtered_customers(_filtered_customers);
    }
    const search_customer_by_phone = (event) => {
      //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
      let query = event.query;
      let _filtered_customers = [];
      for(let i = 0; i < customers.length; i++) {
          let customer = customers[i];
          if (customer.phone_number.toString().toLowerCase().indexOf(query.toLowerCase()) === 0) {
            _filtered_customers.push(customer);
          }
      }
      set_filtered_customers(_filtered_customers);
    }

    const handelcustomer_by_name =  (e) =>
    {
      var have   = false;
      if(e.target.value.ID_customer)
      {
         setid_customer(e.target.value.ID_customer)
         setvisitor_name(e.target.value.visitor_name)
         setphone_number(e.target.value.phone_number)
         setwhatsapp_number(e.target.value.whatsapp_number?'+'+e.target.value.whatsapp_number:null)
         document.getElementById("lvisitor_name").style.color = 'black';
         document.getElementById("lphone_number").style.color = 'black';
         have = true;
         document.getElementById('msg_customer').innerHTML="زبون مسجل مسبقا"
          return;
      }
      else
      {
        if(!have)
        {
          if(id_customer!==null && id_customer!=='')
          {
            setid_customer("");
            setphone_number("");
            setwhatsapp_number(null);
          }

        }
        if(pattern.name_en_ar.test(e.target.value) === false || e.target.value==="" || e.target.value.length>255)
        {
          document.getElementById("lvisitor_name").style.color = 'red';
        }
        else
        {
          document.getElementById("lvisitor_name").style.color = 'black';

        }
        setvisitor_name(e.target.value)
        document.getElementById('msg_customer').innerHTML="زبون جديد"
      }
    }
    const handelcustomer_by_phone =  (e) =>
    {
        var have   = false;
        if(e.target.value.ID_customer)
        {
          setid_customer(e.target.value.ID_customer)
          setvisitor_name(e.target.value.visitor_name)
          setphone_number(e.target.value.phone_number)
          setwhatsapp_number(e.target.value.whatsapp_number?'+'+e.target.value.whatsapp_number:null)
          document.getElementById("lvisitor_name").style.color = 'black';
          document.getElementById("lphone_number").style.color = 'black';
          have = true;
          document.getElementById('msg_customer').innerHTML="زبون مسجل مسبقا"
            return;
        }
        else
        {
          if(!have)
          {
            if(id_customer!==null && id_customer!=='')
            {
              setid_customer("");
              setvisitor_name("");
              setwhatsapp_number(null);
            }

          }
          if(pattern.phone_number.test(e.target.value)=== false || e.target.value.length!==9)
          {
            document.getElementById("lphone_number").style.color = 'red';
          }
          else{
            document.getElementById("lphone_number").style.color = 'black';
          }
          setphone_number(e.target.value)
          document.getElementById('msg_customer').innerHTML="زبون جديد"
        }

    }
    const handel_del_reservation =  (e) =>
    {
      if(e.target.checked)
      {
        set_del_reservation(1)
      }
      else
      {
        set_del_reservation(0)
      }
    }
    const handelpaid = (e) =>
    {
      setpaid(e.target.value)
        if(
            pattern.number_int.test(e.target.value) === true &&  e.target.value*1 > 0
        &&  e.target.value*1 <= sum_total_price(Book.reservationes)-sum_total_paid(Book.reservationes))
        {
            document.getElementById("lpaid").style.color = "black";
        }
        else
        {
            document.getElementById("lpaid").style.color = "red";
        }
    }
    const footerCustomerBooking = (
        <center>
            <Button label="إلغاء" raised onClick={() => setVisible(false)} severity="danger" style={{fontFamily:"Cairo",marginLeft:"10px"}} />
            <Button label=" تاكيد الحجز " raised
              style={{fontFamily:"Cairo",marginRight:"10px",direction:"ltr"}}
              loading={loading_booking} onClick={(e) => BookingNow(e)} autoFocus
            />
        </center>
    );
    //_______________________________________________________________________________________
    //                               Set Apartment
    function BookingNow   (e)
    {
      e.preventDefault();
      set_loading_booking(true);
      booking_dates.length=0;
      booking_apartments_dates[id_apartment].map((index)=> {
        booking_dates.push({"date":Moment(index).format("yyyy-MM-DD")});
      })
      booking_dates = booking_dates.sort((a, b) =>
        a.date > b.date ? 1 : -1,
      );

      var test_validtion = true
      //_______________________________________________________________________________________
      //                         Validtion
      if(pattern.name_en_ar.test(visitor_name) === false || visitor_name==="" || visitor_name.length>200)
      {
        document.getElementById("lvisitor_name").style.color = 'red';
        test_validtion = false
      }
      if(pattern.number_int.test(phone_number)=== false || phone_number==="" || phone_number.length>14)
      {
        document.getElementById("lphone_number").style.color = 'red';
        test_validtion = false
      }
      if(
        whatsapp_number!==null &&
        (pattern.number_whatsapp.test(whatsapp_number)=== false || whatsapp_number==="" || whatsapp_number.length>15)
      )
      {
        document.getElementById("lwhatsapp_number").style.color = 'red';
        test_validtion = false
      }
      if(notes!=="" && notes.length>1000)
      {
        document.getElementById("lnote").style.color = 'red';
        test_validtion = false
      }

      //_______________________________________________________________________________________
      if(test_validtion)
      {
        axios.post( setbooking ,
        {
          "TOKEN": window.localStorage.getItem('token'),
          "id_apartment":id_apartment,
          "id_customer":id_customer,
          "visitor_name":visitor_name,
          "phone_number":phone_number,
          "whatsapp_number":whatsapp_number,
          "notes":notes,
          "booking_dates":booking_dates,
          "time_check_in": "02:00:00",
          "time_check_out": "12:00:00",
          "del_reservation":del_reservation
        }
        ).then((res) =>
        {
          console.log(res)
          set_loading_booking(false);
          if(res.status === 200)
          {
            if (res.data.success === 500 || res.data.error === 1)
            {
              if(res.data.success === 500 )
                Navigate("/logout")
              else
                Navigate('/error', {
                    state: {
                        msg: res.data.msg
                    }
                })
            }
            else if(res.data.success===1)
            {

              setDates([]);
              setid_apartments("");
              setid_customer("");
              setvisitor_name("");
              setphone_number("");
              setwhatsapp_number(null);
              setnote("");
              set_del_reservation(true);
              //__________________________________________
              setVisible(false);
              setBook(res.data.customer)
              booking_dates.length=0;
              setVisibleBooking(true);
              toast.success(res.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              // Get_Send_Whatsapp_Pdf(res.data.customer,'لــديك حجـــوزات جــديده')
              GetApartments()
              GetCustomers()
            }
            else if(res.data.success===0)
            {
              toast.error(res.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
            else if(res.data.success===-1)
            {
              toast.error(res.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                GetApartments();
            }
          }
        }
        ).catch((err) =>
        {
          set_loading_booking(false);
          console.log(err)
        })
      }
      else
      {
        set_loading_booking(false);
        toast.error("ارجو إدخال بيانات الزبون", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    //__________________________________________________________________________
    function PaidBookingNow   (e)
    {
      e.preventDefault();
      set_loading_paid(true);
       var test_validtion = true;
      if (
        pattern.number_int.test(paid) === false
        ||
        paid===""  || paid<=0  || paid > (sum_total_price(Book.reservationes)-sum_total_paid(Book.reservationes))
      )
      {
        document.getElementById("lpaid").style.color = 'red';
        test_validtion = false;
      }
      if(test_validtion)
      {
          axios.post( setpaidbooking ,
          {
            "TOKEN": window.localStorage.getItem('token'),
            "id_customer":Book.ID_customer,
            "id_apartment":Book.ID_apartment,
            "reservationes":Book.reservationes,
            "paid":paid
          }
          ).then((res) =>
          {
            set_loading_paid(false);
            if(res.status === 200)
            {
              if (res.data.success === 500 || res.data.error === 1)
              {
                  Navigate('/error', {
                      state: {
                          msg: res.data.msg
                      }
                  })
              }
              else if(res.data.success===1)
              {
                setpaid("");
                setBook(null);
                setVisibleBooking(false);
                toast.success(res.data.msg, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                  setcustomer_reservationes(res.data);
                  setViewInvoice(true);
                  // Get_Send_Whatsapp_Pdf(res.data.customer,'تــم الدفــع حجـــوزات')

                  //setVisibleBooking(true);
              }
              else if(res.data.success===0)
              {
                toast.error(res.data.msg, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
              }
            }
          }
          ).catch((err) =>
          {
            console.log(err)
          })
      }
      else
      {
        set_loading_paid(false);
        toast.error("ارجو إدخال  قيمة الدفع", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    //_______________________________________________________________________________________
    //                      Get Apartments
    const GetApartments = async () =>
    {
        var response = await axios.post(getapartments_dates_resort,{"TOKEN": window.localStorage.getItem('token')});
        if (response.data.success === 500 || response.data.error === 1)
        {
            Navigate('/error', {
                state: {
                    msg: response.data.msg
                }
            })
        }
        else if(response.data.success===1)
        {

          setApartments(response.data['apartments']);
          apartments.map((apartment) => {
            booking_apartments_dates[apartment.ID_apartment] =[];
          })
        }
    }
    //                      Get Customers
    const GetCustomers = async () =>
    {
        var response = await axios.post(getcustomers,{"TOKEN": window.localStorage.getItem('token'),"state":1});
        if (response.data.success === 500 || response.data.error === 1)
        {
            Navigate('/error', {
                state: {
                    msg: response.data.msg
                }
            })
        }
        else if(response.data.success===1)
        {
          setCustomers(response.data['customers']);

        }
    }
     //                      Send whatsapp pdf
    const Get_Send_Whatsapp_Pdf= async (data,msg) =>
    {
      if(data.ID_customer && data.whatsapp_number)
      {
          var url =UrlًInvoicePdf+'?id='+data.ID_customer+'&token='+window.localStorage.getItem('token');
          var response = await axios.post(UrlًWhatsappMsgInvoice,{
            'type':'invoice',
            'number':data.whatsapp_number,
            'msg': msg,
            'url': url
          }
          ,{
          headers : {
              'Authorization' : 'Bearer '+window.localStorage.getItem('token')
              },
          });
          if(response.status===200)
          {
            if(response.data.success===1)
            {
              toast.success(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
            else if(response.data.success===0)
            {
              toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

            }
          }
      }
    }
    useEffect(() =>
    {
        GetApartments();
        GetCustomers();
    }, []);

    return (
    <div className="page-wrapper" dir="rtl"  id="booking" >
        <div className="content-wrapper">

        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={true}
        transition={Flip}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
          <div className="page-heading">
                <h1 className="page-title"> إنشاء حجوزات </h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="index.html"><i className="la la-home font-20"></i></a>
                    </li>
                </ol>
            </div>



            <div className="row">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-head">
                          <cente>
                            <div className="ibox-title" dir="ltr">{window.localStorage.getItem('resort_name_en')} - {window.localStorage.getItem('resort_name_ar')}</div>
                          </cente>
                        </div>
                        <div className="ibox-body" dir="rtl">
                              <div className="row">
                                {apartments.map((index) =>
                                {

                                  return(
                                      <div className="col-md-4" style={{paddingBottom:"20px"}}>
                                          <div className="card" style={{width:"100%"}}>
                                            <Slide autoplay={false}  >
                                              {
                                                index.images.length > 0 ?
                                                (
                                                  index.images.map((image) =>
                                                  {
                                                    return (
                                                      <div className="column">
                                                        <a data-fancybox={index.number} href={getimagesapartment + image}>
                                                          <div className="each-slide-effect">
                                                              <div  style={{ 'backgroundImage': `url(${getimagesapartment+image})`,width:"100%",height:"350px" }}>
                                                                  <span className="price"> {index.price+' د.ل '}/ليلة</span>
                                                              </div>
                                                          </div>
                                                        </a>
                                                      </div>
                                                    )
                                                  })
                                                )
                                                :
                                                (
                                                <>
                                                  <div className="each-slide-effect">
                                                    <div style={{ 'backgroundImage': `url(${getimagesapartment+"apartment.png"})`,width:"100%",height:"350px" }}>
                                                        {/* <span>Slide 1</span> */}
                                                    </div>
                                                  </div>
                                                </>
                                                )
                                              }
                                            </Slide>
                                            <table className="table table-bordered" style={{marginBottom:"0px",marginTop:"0px"}}>
                                                <tr>
                                                    <th  style={{textAlign:"center",width:"50%"}}>
                                                    <h6 > <i className="fa fa-home text-info"></i>  { view_apartment_type_ar(index.type)+ '  ' +index.number} </h6>
                                                    </th>
                                                    <th style={{textAlign:"center",width:"50%"}}>
                                                    <h6 > <i className="fa fa-users text-info"></i>  أشخاص {index.number_people} </h6>
                                                    </th>
                                                </tr>
                                            </table>
                                            {
                                              index.images.length > 0 ?
                                              (
                                              index.images.map((image) => {
                                                return (
                                                  <div className="column" style={{display:"none"}}>
                                                    <a data-fancybox={index.number} href={getimagesapartment + image}>
                                                    <img className="img-circle" alt="logo" src={getimagesapartment +image} width="50" height="50"/>
                                                    </a>
                                                  </div>
                                                )
                                              })
                                              )
                                              :
                                              (null)
                                            }

                                            <div dir="rtl" style={{fontFamily:"Cairo"}}>
                                                <Calendar
                                                minDate={today}
                                                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                                value={booking_apartments_dates[index.ID_apartment]}
                                                onChange={(e) => handelDates(e,index.ID_apartment)}
                                                dateTemplate={(e) => dateTemplate(e,index.dates_reservationes)}
                                                disabledDates={dates_close(index.dates_reservationes)}
                                                selectionMode="multiple"
                                                readOnlyInput
                                                locale="ar"
                                                showButtonBar
                                                inline
                                                style={{width:"100%",fontFamily: "Cairo",padding:"0px"}}
                                                panelStyle={{fontFamily: "Cairo"}}
                                                dateFormat="yyyy-MM-DD"

                                                />
                                            </div>

                                                <div className="row">
                                                  <div className="col-md-8">
                                                    <div className="d-inline-flex">
                                                        <div className="px-2" style={{borderRight:"1px solid rgba(0,0,0,.1"}}>
                                                            <div className="text-muted"> السعر الكلي</div>
                                                            <div>
                                                                <span className="h4 m-1">
                                                                  {
                                                                  booking_apartments_dates[index.ID_apartment] ?
                                                                  ( <>د،ل {index.price* booking_apartments_dates[index.ID_apartment].length}</> )
                                                                  :
                                                                  (<>د،ل { index.price*0 }</>)

                                                                  }
                                                                </span>
                                                                <span className="text-danger ml-2"><i className="fa fa-level-down"></i>
                                                                {index.price}
                                                                X
                                                                {
                                                                    booking_apartments_dates[index.ID_apartment] ?
                                                                  ( booking_apartments_dates[index.ID_apartment].length)
                                                                  :
                                                                  (0)
                                                                }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="px-3">
                                                            <div className="text-muted"> عدد الأيام</div>
                                                            <div>
                                                                <span className="text-success h4 m-0">

                                                                  {
                                                                  booking_apartments_dates[index.ID_apartment] ?
                                                                  (booking_apartments_dates[index.ID_apartment].length)
                                                                  :
                                                                  (0)
                                                                  }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-3" style={{margin:"0",paddingTop:"5px"}}>
                                                      <Button  label="حجز" raised  className="col-md-12" style={{fontFamily:"Cairo"}} onClick={(e) => handelBooking(e,index,index.ID_apartment)} />
                                                  </div>

                                                </div>
                                          </div>
                                      </div>
                                    )
                                  }
                                )}
                                  <Dialog header="اتمام الحجز"
                                  visible={visible}
                                  style={{ width: '60vw',fontFamily:"Cairo" }}
                                  breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                                  onHide={() => setVisible(false)}
                                  footer={footerCustomerBooking}
                                  dir="rtl"
                                  >
                                      <table className="table table-bordered table-striped no-margin table-invoice">
                                        <thead className="color-view bg-ebony ">
                                            <tr style={{color:"white",fontSize:"10px"}}>
                                                <th>نوع الاقامة</th>
                                                <th> عدد الاشخاص</th>
                                                <th> عدد الغرف</th>
                                                <th>الملحقات</th>
                                                <th> عدد الايام</th>
                                                <th>سعر اليوم</th>
                                                <th>الإجمالي</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{fontSize:"15px"}}>
                                              <tr>
                                              <td>{view_apartment_type_ar(apartment?.type) +" "+apartment?.number }</td>
                                              <td>{apartment?.number_people} أشخاص</td>
                                              <td>
                                                  <p className="badge badge-primary badge-square ml-1" style={{fontSize:"12px"}} >{apartment.number_bedrooms} غرف نوم </p>
                                                  <p className="badge badge-primary badge-square ml-1" style={{fontSize:"12px"}} >{apartment.number_living_room} غرف معيشة</p>
                                                  <p className="badge badge-primary badge-square  ml-1" style={{fontSize:"12px"}} >{apartment.number_kitchen}  مطبخ</p>
                                                  <p className="badge badge-primary badge-square" style={{fontSize:"12px"}} >{apartment.number_bathrooms}  حمام</p>
                                              </td>
                                              <td>
                                                <div className="text-muted card-subtitle">
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  ثلاجة	 </p>
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  غاز		 </p>
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  تلفزيون	 </p>
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  مولد	 </p><br></br>
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  شواية	 </p>
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  مسبح	 </p>
                                                    <p style={{display:"inline"}}><i className="fa fa-check  text-info"></i>  انترنت	 </p>
                                                  </div>
                                              </td>
                                              <td>{booking_apartments_dates[id_apartment]?.length } أيام</td>
                                              <td>
                                                <h6 className="badge badge-primary badge-square " style={{fontSize:"15px"}} > {apartment?.price} د.ل </h6>
                                              </td>
                                              <td>
                                                  <h6 className="badge badge-danger badge-square " style={{fontSize:"15px"}} >
                                                  {booking_apartments_dates[id_apartment]?.length*apartment?.price} د،ل
                                                  </h6>
                                              </td>
                                              </tr>
                                        </tbody>
                                    </table>
                                    <div className="row pt-3" style={{fontFamily:"Cairo"}} >
                                           <div className="col-md-12 form-group">
                                            <center>
                                            <table className="table table-bordered table-striped no-margin table-invoice">
                                              <thead className="color-view  ">
                                                <tr style={{fontSize:"10px"}}>
                                                    <th rowSpan={2}>حالة الحجز</th>
                                                    <th>تاريخ  الغاء الحجز</th>
                                                    <th>وقت  الغاء الحجز</th>

                                                </tr>
                                                </thead>
                                                <tbody style={{fontSize:"15px"}}>
                                                  <tr>

                                                    <td >
                                                      <label className="ui-checkbox ui-checkbox-success">
                                                        <input type="checkbox" name="del_reservation" id="del_reservation" value="1"  defaultChecked={del_reservation} onChange={e=>handel_del_reservation(e)}  />
                                                        حجز مبدئي
                                                        <span className="input-span"></span>
                                                    </label>
                                                    </td>
                                                    <td className="text-danger">
                                                      {
                                                        del_reservation ?
                                                        (
                                                          <>
                                                          {dates && dates[0]? after_date_delelete_reservation(dates[0]):NULL}
                                                          <br></br>
                                                          {dates &&  dates[0]? moment(date_delelete_reservation(dates[0])).format("YYYY-MM-DD"):null}
                                                          </>
                                                        )
                                                        :("-")
                                                      }
                                                    </td>
                                                    <td className="text-danger">
                                                      {
                                                        del_reservation ?
                                                        (
                                                          dates &&  dates[0] ? moment(date_delelete_reservation(dates[0])).format("h:m A"):null
                                                        )
                                                        :("-")
                                                      }
                                                    </td>
                                                  </tr>
                                                </tbody>
                                            </table>
                                              </center>
                                              <hr></hr>
                                            </div>

                                        <div className="col-md-6">
                                          <label id="lvisitor_name"> الأسم بالكامل *
                                               <i className="fa fa-user pr-1" ></i>
                                          </label>
                                          < label id="msg_customer" style={{paddingRight:"50px"}}></label>
                                          <AutoComplete value={visitor_name} suggestions={filtered_customers} completeMethod={(e)=> search_customer_by_name(e)}
                                          maxLength={255} placeholder=" الاسم" className="col-md-12 pr-0" style={{fontFamily:"Cairo"}} inputStyle={{fontFamily:"Cairo"}} panelStyle={{fontFamily:"Cairo",textAlign:"right"}}
                                          virtualScrollerOptions={{ itemSize: 38 }} field="visitor_name" dropdown onChange={(e) => handelcustomer_by_name(e)} scrollHeight="400px" />

                                        </div>

                                        <div className="col-md-3">
                                            <label id="lphone_number"> رقم الهاتف *
                                            <i className="fa fa-phone pr-1" ></i>
                                            </label>
                                            <div className="input-group" >
                                            <AutoComplete value={phone_number} suggestions={filtered_customers} completeMethod={(e)=> search_customer_by_phone(e)}
                                            maxLength={9} placeholder="9x1234567"  className="col-md-12 pr-0" style={{fontFamily:"Cairo"}} inputStyle={{fontFamily:"Cairo"}} panelStyle={{fontFamily:"Cairo",textAlign:"right"}}
                                            virtualScrollerOptions={{ itemSize: 38 }} field="phone_number" dropdown onChange={(e) => handelcustomer_by_phone(e)} scrollHeight="400px"  />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label id="lwhatsapp_number"> رقم واتس اب
                                            <i className="fa fa-whatsapp pr-1" ></i>
                                            </label>

                                            <div className="input-group" dir="ltr">
                                            <PhoneInput
                                              country='LY'
                                              defaultCountry="LY"
                                              placeholder="ادخل رقم واتس اب"
                                              value={whatsapp_number}  maxLength={15} name="whatsapp_number" id="whatsapp_number"
                                              onChange={setwhatsapp_number}
                                              className="p-inputtext p-component p-autocomplete-input p-autocomplete-dd-input p-autocomplete-input p-autocomplete-dd-input col-md-12 pr-0"
                                              />
                                            </div>
                                        </div>
                                        <div className="col-md-12 form-group pt-3">
                                            <label id="lnote">ملاحظات</label>
                                            <div className="input-group">
                                                <textarea className="form-control " rows="4" cols="50"  name="note" id="note"
                                                placeholder="ادخال ملاحظات الحجز"  onChange={e=> setnote(e.target.value)} maxLength={1000}  ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                  </Dialog>
                                   {
                                    Book ?
                                    (
                                      <>
                                        <Dialog dir="rtl" header="إيصال قبض الحجوزات القادمة"
                                            maximizable style={{ width: '50vw',fontFamily:"Cairo" }}
                                            visible={visibleBooking}   onHide={()=>setVisibleBooking(false)}
                                          >
                                              <InvoiceContext.Provider value={Book} >
                                                  <PaidInvoice></PaidInvoice>
                                                  {
                                                      sum_total_price(Book.reservationes)-sum_total_paid(Book.reservationes)>0 ?
                                                      (
                                                      <center>
                                                          <form>
                                                              <div className="col-md-3 form-group">
                                                                  <label id="lpaid" >  قيمة الدفع</label>

                                                                    <div className="input-group flex-1">
                                                                        <InputNumber inputId="integeronly"   onValueChange={(e) => handelpaid(e)} min={0} max={sum_total_price(Book.reservationes)-sum_total_paid(Book.reservationes)}  />
                                                                          <span className="p-inputgroup-addon">
                                                                              د.ل
                                                                          </span>
                                                                  </div>

                                                              </div>

                                                              <div className="col-md-12 form-group">
                                                                  <div className="text-center">
                                                                    <Button label="إلغاء" raised  severity="danger"  style={{fontFamily:"Cairo"}} onClick={handel_setVisibleBooking}  />
                                                                    <Button  label=" قبض " raised severity="success"
                                                                      onClick={(e) => PaidBookingNow(e)} loading={loading_paid} autoFocus
                                                                      style={{fontFamily:"Cairo",direction:"ltr",marginRight:"10px"}} s
                                                                    />
                                                                  </div>
                                                              </div>
                                                          </form>
                                                      </center>
                                                      )
                                                      :
                                                      (null)
                                                  }
                                              </InvoiceContext.Provider>
                                          </Dialog>
                                      </>
                                    )
                                    :
                                    (null)
                                }
                                {
                                  ViewInvoice ?
                                  (
                                    <>
                                    <Dialog dir="rtl" header="فاتورة الحجوزات"  visible={ViewInvoice} maximizable style={{ width: '50vw',fontFamily:"Cairo" }} onHide={() => setViewInvoice(false)}>
                                      <InvoiceContext.Provider value={customer_reservationes} >
                                        {ViewInvoice &&  <Invoice></Invoice>}
                                      </InvoiceContext.Provider>
                                    </Dialog>
                                    </>
                                  )
                                  :
                                  (null)
                                }
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default BookingNew


