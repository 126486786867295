
import axios from "axios";

import moment from "moment";
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css';
import { Flip, ToastContainer, toast } from 'react-toastify';
import '../../Helper/links';
import { getallusers, getapartments_resort, getpaymentsdate, getpaymentsmonth, getpaymentsyear } from "../../Helper/links";
import count_days from "../../functions/count_days";
import sum_amount from "../../functions/sum_total_amount";
import view_apartment_type_ar from "../../functions/view_apartment_type";
import view_day_ar from "../../functions/view_day_ar";
import { InvoiceContext } from './invoices/Invoice_Context';
import InvoicePaymentsDay from "./invoices/invoice_payments_day";
import InvoicePaymentsMonth from "./invoices/invoice_payments_month";
import InvoicePaymentsYear from "./invoices/invoice_payments_year";

import { MultiSelect } from 'primereact/multiselect';

import ReactToPrint from 'react-to-print';


function Payments()
{
    const Navigate = useNavigate();
    const componentRef = useRef();
    const buttonRef = useRef();
    const [isShown, setIsShown] = useState(false);
    addLocale('ar', {
        firstDayOfWeek: 6,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
        monthNames: ['January-01', 'February-02', 'March-03', 'April-04', 'May-05', 'June-06', 'July-07', 'August-08', 'September-09', 'October-10', 'November-11', 'December-12']    ,
        monthNamesShort: ['Jan-01', 'Feb-02', 'Mar-03', 'Apr-04', 'May-05', 'Jun-06', 'Jul-07', 'Aug-08', 'Sep-09', 'Oct-10', 'Nov-11', 'Dec-12'],
        today: 'اليوم',
        clear: 'تفريغ',
      });
    const[loading_report_day , set_loading_report_day] = useState(false);
    const[loading_report_month , set_loading_report_month] = useState(false);
    const[loading_report_year , set_loading_report_year] = useState(false);

    //____________________________________________________________
    const [apartments, setApartments] = useState([]);
    const [apartments_view, setApartments_view] = useState([]);

    const [users, set_users] = useState([]);
    const [users_view, set_users_view] = useState([]);


    const [payments_day, setPayments_day] = useState([]);
    const [payments_month, setPayments_month] = useState([]);
    const [payments_year, setPayments_year] = useState([]);

    //____________________________________________________________

    const [selectedpayments, setSelectedpayments] = useState([]);
    const [date, setDate] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [year, setYear] = useState(new Date());
    const [PrintInvoicePayments_Day, setPrintInvoicePayments_Day] = useState(false);
    const [PrintInvoicePayments_Month, setPrintInvoicePaymentsMonth] = useState(false);
    const [PrintInvoicePayments_Year, setPrintInvoicePaymentsYear] = useState(false);
    //_______________________________________________________________________________________
    //                      Get Reservationes
    const GetApartment = async () =>
    {
        var response = await axios.post(getapartments_resort,{"TOKEN": window.localStorage.getItem('token'),"del":0});

        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                setApartments(response.data.apartments);
                setApartments_view(response.data.apartments);
            }
        }
    }
    const GetUsers = async () =>
    {
        var response = await axios.post(getallusers,{"TOKEN": window.localStorage.getItem('token')});
        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                set_users(response.data.users);
            }
        }
    }
    const GetPaymentsDay = async () =>
    {
        set_loading_report_day(true);
        var response = await axios.post(getpaymentsdate,{
            "TOKEN" : window.localStorage.getItem('token'),
            "date" : moment(date).format("yyyy-MM-DD"),
            "users_view":users_view,
            "apartments_view":apartments_view
        });
        if(response.status===200)
        {
            set_loading_report_day(false);
            if (response.data.success === 500 || response.data.error === 1)
            {
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                setPayments_day(response.data.payments);
            }
            else if(response.data.success===0)
            {
                setPayments_day([]);

            }
        }
    }
    const GetPaymentsMonth = async () =>
    {
        set_loading_report_month(true);
        var response = await axios.post(getpaymentsmonth,{
            "TOKEN" : window.localStorage.getItem('token'),
            "month" : moment(month).format("yyyy-MM"),
            "users_view":users_view,
            "apartments_view":apartments_view
        });
        if(response.status===200)
        {
            set_loading_report_month(false);
            if (response.data.success === 500 || response.data.error === 1)
            {
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                var payments_month_abbreviated =[];
                var day_view=0;
                var count_pay_day=0;
                var total_paid_day=0;
                var start = false;
                // eslint-disable-next-line array-callback-return
                response.data.payments.map((pay)=>{
                    if(moment(pay.date_payment).format("Y-MM-D") !== moment(day_view).format("Y-MM-D") && start)
                    {
                        payments_month_abbreviated.push({"day":day_view,"count_pay_day":count_pay_day,"amount":total_paid_day})
                        day_view = moment(pay.date_payment).format("Y-MM-D");
                        count_pay_day=0;
                        total_paid_day=0;
                    }

                        if(!start)
                        {
                            start = true;
                            day_view = moment(pay.date_payment).format("Y-MM-D");

                        }

                        count_pay_day++;
                        total_paid_day+=pay.amount;

                })
                payments_month_abbreviated.push({"day":day_view,"count_pay_day":count_pay_day,"amount":total_paid_day})
                setPayments_month(payments_month_abbreviated);
            }
            else if(response.data.success===0)
            {
                setPayments_month([]);

            }
        }
    }
    const GetPaymentsYear = async () =>
    {
        set_loading_report_year(true);
        var response = await axios.post(getpaymentsyear,{
            "TOKEN" : window.localStorage.getItem('token'),
            "year" : moment(year).format("yyyy"),
            "users_view":users_view,
            "apartments_view":apartments_view
        });
        console.log(response)
        if(response.status===200)
        {
            set_loading_report_year(false);
            if (response.data.success === 500 || response.data.error === 1)
            {
                // Navigate('/error', {
                //     state: {
                //         msg: response.data.msg
                //     }
                // })
            }
            else if(response.data.success===1)
            {
                setPayments_year( response.data.payments);
            }
            else if(response.data.success===0)
            {
                setPayments_year([]);
                toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
        }
    }
    const ApartmentsViewItemTemplate = (apt) => {
        return (
            <div className="flex align-items-center gap-1" style={{fontFamily:"Cairo",direction:"rtl"}} >
                <span>{view_apartment_type_ar(apt.type)} - {apt.number}</span>
            </div>
        );
    };
    const UsersViewItemTemplate = (user) => {
        return (
            <div className="flex align-items-center gap-1" style={{fontFamily:"Cairo",direction:"rtl"}} >
                <span>{user.name}</span>
            </div>
        );
    };
    //____________________________________________________________
    const handel_Day = (e) =>{
        setDate(e.target.value);
    };
    const handel_Month = (e) =>{
       setMonth(e.target.value);
    };
    const handel_Year = (e) =>{
        setYear(e.target.value);
     };
    const View_Date = (data)=>{
        return (
            <strong >
                {moment(data.date_payment).format('yyyy-MM-DD')}
                <br></br>
                {moment(data.date_payment).format('hh:mm A')}
            </strong>
        )
    }
    const View_Day = (data)=>{
        return (
            <strong >
                {moment(data.day).format('yyyy-MM-DD')}
                <br></br>
                {view_day_ar(data.day)}
            </strong>
        )
    }
    const View_Month = (data)=>{
        return (
            <strong >
                {moment(data.month).format('yyyy-MM')}
                <br></br>
                {moment(data.month).format('MMMM')}
            </strong>
        )
    }
    const View_total_price = (data)=>{
        return (
            <strong >
                {data.total_price} د،ل
            </strong>
        )
    }
    const View_Amount = (data)=>{
        if(data.amount<0)
        {
            return (
                <strong style={{color:"red"}}>
                    {data.amount} د.ل
                </strong>
            )
        }
        else
        {
            return (
                <strong style={{color:"green"}}>
                    {data.amount} د.ل
                </strong>
            )
        }
    }
    const View_Days = (data)=>{
        return (
            <strong >
                {count_days([data])} أيام
            </strong>
        )
    }
    const view_Apartment = (data)=>{
        return (
            <strong >
                {view_apartment_type_ar(data.type)} -  {data.number}
            </strong>
        )
    }
    const state_res = (data)=>{
        if(data.del===1)
         {
            return (
                <strong style={{color:"red"}}>
                    ملغي
                </strong>
            )
         }
         else if(data.total_price===data.total_paid)
            {
                return (
                    <strong style={{color:"green"}}>
                        خالص
                    </strong>
                )
            }
            else
            {
                return (
                    <strong style={{color:"red"}}>
                    مدفوع جزاء
                    </strong>
                )

            }
    }
     useEffect(()  =>
    {
        GetApartment();
        GetUsers();
        GetPaymentsDay();
        GetPaymentsMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
    <div className="page-wrapper" dir="rtl" >
        <div className="content-wrapper">
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Flip}
            />
            <div className="page-heading">
                <h1 className="page-title"> المدفوعات </h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="index.html"><i className="la la-home font-20"></i></a>
                    </li>
                </ol>
            </div>

            <TabView style={{width:"100%"}} >
                <TabPanel header="مدفوعات اليومية" leftIcon="pi pi-calendar mr-0" style={{fontFamily:"Cairo"}}  >
                    <div className="card" >
                        <div className="col-md-12" style={{fontFamily:"Cairo"}}>
                            <div className="row">
                                <Calendar value={date} className="col-md-3"
                                showButtonBar
                                onChange={(e) => handel_Day(e)}
                                maxDate={new Date()}
                                dateFormat="yy-mm-dd"
                                style={{padding:"0"}}
                                locale="ar"
                                />
                                <MultiSelect className="col-md-3"
                                options={apartments}
                                value={apartments_view}
                                itemTemplate={ApartmentsViewItemTemplate}
                                onChange={(e) => setApartments_view(e.target.value)}
                                optionLabel="number"
                                display="chip"
                                placeholder="اختار مكان الاقامة"
                                maxSelectedLabels={3}
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                                <MultiSelect
                                    options={users}
                                    value={users_view}
                                    itemTemplate={UsersViewItemTemplate}
                                    onChange={(e) => set_users_view(e.target.value)}
                                    optionLabel="name"
                                    display="chip"
                                    placeholder="اختار المستخدمين"
                                    className="col-md-3"
                                    maxSelectedLabels={3}
                                    style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                                <Button
                                    type="button"
                                    icon="pi pi-filter-slash"
                                    label="عرض"
                                    outlined
                                    onClick={GetPaymentsDay}
                                    style={{fontFamily:"Cairo",paddingRight:"10PX",display:"inline"}} className="col-md-1"
                                />
                            </div>
                        </div>
                    <DataTable value={payments_day} loading={loading_report_day}
                        dataKey="ID_payment"
                        selection={selectedpayments} onSelectionChange={(e) => setSelectedpayments(e.value)}
                        metaKeySelection={false} dragSelection
                        stripedRows
                        scrollable scrollHeight="600px"
                        emptyMessage={<center><h4>لا يوجد مدفوعات</h4></center>}
                        tableStyle={{ minWidth: '50rem' }}
                        style={{fontFamily:"Cairo",direction:"rtl"}}
                    >
                            <Column field="date_payment" header="تاريخ الفاتورة" body={View_Date} sortable style={{ width: '5%',textAlign:"right" }}></Column>
                            <Column field="ID_payment" header="الفاتورة"  style={{ width: '1%',textAlign:"right" }}></Column>
                            <Column field="visitor_name" header="الزبون"  style={{ width: '5%',textAlign:"right" }}></Column>
                            <Column field="number" header="الشالية" body={view_Apartment}  style={{ width: '1%',textAlign:"right" }}></Column>
                            <Column header="أيام"  body={View_Days} style={{ width: '2%',textAlign:"right" }}></Column>
                            <Column field="ID_reservation" header="الحجز"  style={{ width: '2%',textAlign:"right" }}></Column>
                            <Column field="total_price" header="السعر الكلي" body={View_total_price}  style={{ width: '2%',textAlign:"right" }}></Column>
                            <Column field="operation_type" header="نوع العملية"  style={{ width: '2%',textAlign:"right" }}></Column>
                            <Column field="amount" header="المبلغ" body={View_Amount}  style={{ width: '1%',textAlign:"right" }}></Column>
                            <Column  header="حالة الحجز" body={state_res}  style={{ width: '1%',textAlign:"right" }}></Column>
                            <Column field="name_user" header="الموظف"  style={{ width: '2%',textAlign:"right" }}></Column>

                        </DataTable>
                    </div>
                    <table className="table table-striped no-margin table-invoice" style={{fontSize:"20px"}}>
                            <thead >
                                <tr >
                                    <th style={{fontSize:"20px"}}>عدد الفواتير</th>
                                    <th style={{fontSize:"20px"}}>إجمالي المبلغ</th>
                                </tr>
                                <tr >
                                    <td >{payments_day.length}</td>
                                    {
                                    sum_amount(payments_day)<0?
                                    (<td style={{color:"red"}}>{sum_amount(payments_day)}  د.ل</td>)
                                    :
                                    <td style={{color:"green"}}>{sum_amount(payments_day)}  د.ل</td>
                                    }

                                </tr>
                            </thead>
                    </table>
                    <hr></hr>
                    <div className="col-md-12" dir="ltr">
                        <Button dir='ltr' type="button"  label=" عــرض "  icon='fa fa-file' raised
                        onClick={e=>setPrintInvoicePayments_Day(true)}
                        />
                    </div>
                </TabPanel>

                <TabPanel header="مدفوعات الشهرية" leftIcon="pi pi-calendar mr-0" style={{fontFamily:"Cairo"}}  >
                    <div className="card" >
                        <div className="col-md-12" style={{fontFamily:"Cairo"}}>
                            <div className="row">
                                <Calendar value={month} className="col-md-3" view="month"
                                showButtonBar
                                onChange={(e) => handel_Month(e)}
                                maxDate={new Date()}
                                dateFormat="yy-mm"
                                style={{padding:"0"}}
                                locale="ar"
                                panelStyle={{width:"100px",fontSize:"15px"}}
                                />
                                 <MultiSelect className="col-md-3"
                                options={apartments}
                                value={apartments_view}
                                itemTemplate={ApartmentsViewItemTemplate}
                                onChange={(e) => setApartments_view(e.target.value)}
                                optionLabel="number"
                                display="chip"
                                placeholder="اختار مكان الاقامة"
                                maxSelectedLabels={3}
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                                <MultiSelect
                                    options={users}
                                    value={users_view}
                                    itemTemplate={UsersViewItemTemplate}
                                    onChange={(e) => set_users_view(e.target.value)}
                                    optionLabel="name"
                                    display="chip"
                                    placeholder="اختار المستخدمين"
                                    className="col-md-3"
                                    maxSelectedLabels={3}
                                    style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                                <Button
                                type="button"
                                icon="pi pi-filter-slash"
                                label="عرض"
                                outlined
                                onClick={GetPaymentsMonth}
                                style={{fontFamily:"Cairo",paddingRight:"10PX",display:"inline"}} className="col-md-1"
                                />
                            </div>
                        </div>

                    <DataTable value={payments_month} loading={loading_report_month}
                        selectionMode="multiple"
                        metaKeySelection={false} dragSelection
                        stripedRows
                        scrollable scrollHeight="600px"
                        emptyMessage={<center><h4>لا يوجد مدفوعات</h4></center>}
                        tableStyle={{ minWidth: '50rem' }}
                        style={{fontFamily:"Cairo",direction:"rtl"}} >
                            <Column field="day" header="يوم" body={View_Day} sortable style={{ width: '1%',textAlign:"right" }}></Column>
                            <Column field="count_pay_day" header="عدد الدفعات"  style={{ width: '1%',textAlign:"right" }}></Column>
                            <Column field="amount" header="المبلغ" body={View_Amount}  style={{ width: '1%',textAlign:"right",color:"green" }}></Column>
                    </DataTable>
                    </div>
                    <table className="table table-striped no-margin table-invoice" style={{fontSize:"20px"}}>
                            <thead >
                                <tr >
                                    <th  style={{fontSize:"20px"}}>عدد الايام</th>
                                    <th style={{fontSize:"20px"}}>إجمالي المبلغ</th>
                                </tr>
                                <tr >
                                    <td >{payments_month.length}</td>
                                    <td>
                                    {
                                        sum_amount(payments_month)<0?
                                        (<td style={{color:"red"}}>{sum_amount(payments_month)}  د.ل</td>)
                                        :
                                        <td style={{color:"green"}}>{sum_amount(payments_month)}  د.ل</td>
                                    }
                                    </td>

                                </tr>
                            </thead>
                    </table>
                    <hr></hr>
                    <div className="col-md-12" dir="ltr">
                        <Button dir='ltr' type="button"  label=" عــرض "  icon='fa fa-file' raised
                        onClick={e=>setPrintInvoicePaymentsMonth(true)}
                        />
                    </div>
                </TabPanel>

                <TabPanel header="مدفوعات السنوية" leftIcon="pi pi-calendar mr-0" style={{fontFamily:"Cairo"}}  >
                    <div className="card" >
                        <div className="col-md-12" style={{fontFamily:"Cairo"}}>
                            <div className="row">
                                <Calendar value={month} className="col-md-3" view="year"
                                showButtonBar
                                onChange={(e) => handel_Year(e)}
                                maxDate={new Date()}
                                dateFormat="yy"
                                style={{padding:"0"}}
                                locale="ar"
                                panelStyle={{width:"100px",fontSize:"15px"}}
                                />
                                <MultiSelect className="col-md-3"
                                options={apartments}
                                value={apartments_view}
                                itemTemplate={ApartmentsViewItemTemplate}
                                onChange={(e) => setApartments_view(e.target.value)}
                                optionLabel="number"
                                display="chip"
                                placeholder="اختار مكان الاقامة"
                                maxSelectedLabels={3}
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                                <MultiSelect
                                    options={users}
                                    value={users_view}
                                    itemTemplate={UsersViewItemTemplate}
                                    onChange={(e) => set_users_view(e.target.value)}
                                    optionLabel="name"
                                    display="chip"
                                    placeholder="اختار المستخدمين"
                                    className="col-md-3"
                                    maxSelectedLabels={3}
                                    style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                                <Button
                                type="button"
                                icon="pi pi-filter-slash"
                                label="عرض"
                                outlined
                                onClick={GetPaymentsYear}
                                style={{fontFamily:"Cairo",paddingRight:"10PX",display:"inline"}} className="col-md-1"
                                />
                            </div>
                        </div>

                    <DataTable value={payments_year} loading={loading_report_year}
                        selectionMode="multiple"
                        metaKeySelection={false} dragSelection
                        stripedRows
                        scrollable scrollHeight="600px"
                        emptyMessage={<center><h4>لا يوجد مدفوعات</h4></center>}
                        tableStyle={{ minWidth: '50rem' }}
                        style={{fontFamily:"Cairo",direction:"rtl"}} >
                            <Column field="month" header="الشهر" body={View_Month} sortable style={{ width: '1%',textAlign:"right" }}></Column>
                            {/* <Column field="count_pay_day" header="عدد الدفعات"  style={{ width: '1%',textAlign:"right" }}></Column> */}
                            <Column field="amount" header="المبلغ" body={View_Amount}  style={{ width: '1%',textAlign:"right",color:"green" }}></Column>
                    </DataTable>
                    </div>
                    <table className="table table-striped no-margin table-invoice" style={{fontSize:"20px"}}>
                            <thead >
                                <tr >
                                    <th  style={{fontSize:"20px"}}>عدد  الاشهر</th>
                                    <th style={{fontSize:"20px"}}>إجمالي المبلغ</th>
                                </tr>
                                <tr >
                                    <td >{payments_year.length}</td>
                                    <td>
                                    {
                                        sum_amount(payments_year)<0?
                                        (<td style={{color:"red"}}>{sum_amount(payments_year)}  د.ل</td>)
                                        :
                                        <td style={{color:"green"}}>{sum_amount(payments_year)}  د.ل</td>
                                    }
                                    </td>

                                </tr>
                            </thead>
                    </table>
                    <hr></hr>
                    <div className="col-md-12" dir="ltr">
                        <Button dir='ltr' type="button"  label=" عــرض "  icon='fa fa-file' raised
                        onClick={e=>setPrintInvoicePaymentsYear(true)}
                        />
                    </div>
                </TabPanel>
            </TabView>
            {
                PrintInvoicePayments_Day ?
                (
                    <>
                        <Dialog  dir="rtl" header="فاتورة الحجوزات"  visible={PrintInvoicePayments_Day} maximizable style={{ width: '50vw',fontFamily:"Cairo" }}
                        onHide={() =>setPrintInvoicePayments_Day(false)}
                        footer={
                            <div className="text-center">
                            <ReactToPrint
                                trigger={
                                    () =>  <Button dir='ltr' type="button"  label=" طبــاعة "  icon='fa fa-print' raised
                                    ref={buttonRef}  onClick={(e)=> setIsShown(current => !current)}
                                    />
                                }
                                content={() => componentRef.current}
                            />
                        </div>
                        }
                        >
                            <InvoiceContext.Provider value={payments_day}   >
                            {PrintInvoicePayments_Day &&  <div ref={componentRef}>  <InvoicePaymentsDay  ></InvoicePaymentsDay></div>}
                            </InvoiceContext.Provider>
                        </Dialog>
                    </>

                )
                :
                (null)
            }
            {
                PrintInvoicePayments_Month ?
                (
                    <>
                        <Dialog dir="rtl" header="فاتورة الحجوزات" visible={PrintInvoicePayments_Month} maximizable style={{ width: '50vw',fontFamily:"Cairo" }}
                        onHide={() => setPrintInvoicePaymentsMonth(false)}
                        footer={
                            <div className="text-center">
                            <ReactToPrint
                                trigger={
                                    () =>  <Button dir='ltr' type="button"  label=" طبــاعة "  icon='fa fa-print' raised
                                    ref={buttonRef}  onClick={(e)=> setIsShown(current => !current)}
                                    />
                                }
                                content={() => componentRef.current}
                            />
                        </div>
                        }
                        >
                            <InvoiceContext.Provider value={payments_month}>
                            {PrintInvoicePayments_Month &&  <div ref={componentRef}>   <InvoicePaymentsMonth></InvoicePaymentsMonth></div>}
                            </InvoiceContext.Provider>

                        </Dialog>
                    </>
                )
                :
                (null)
            }
            {
                PrintInvoicePayments_Year ?
                (
                    <>
                        <Dialog dir="rtl" header="فاتورة الحجوزات" visible={PrintInvoicePayments_Year} maximizable style={{ width: '50vw',fontFamily:"Cairo" }}
                        onHide={() => setPrintInvoicePaymentsYear(false)}
                        footer={
                            <div className="text-center">
                            <ReactToPrint
                                trigger={
                                    () =>  <Button dir='ltr' type="button"  label=" طبــاعة "  icon='fa fa-print' raised
                                    ref={buttonRef}  onClick={(e)=> setIsShown(current => !current)}
                                    />
                                }
                                content={() => componentRef.current}
                            />
                        </div>
                        }
                        >
                            <InvoiceContext.Provider value={payments_year}>
                            {PrintInvoicePayments_Year &&  <div ref={componentRef}>   <InvoicePaymentsYear></InvoicePaymentsYear></div>}
                            </InvoiceContext.Provider>

                        </Dialog>
                    </>
                )
                :
                (null)
            }
        </div>
    </div>
    )
}

export default Payments


