const date_minus_day = (currentDate, daysToSubtract) =>{
    daysToSubtract = daysToSubtract || 0

    // Instantiate a new object based on the current Date
    const pastDate = new Date(currentDate)

    // Subtract  the number of days
    pastDate.setDate(pastDate.getDate() - daysToSubtract)

    return pastDate
}

export default date_minus_day;