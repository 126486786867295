import React from 'react'

function Customer_New() {
  return (
    <div className="page-wrapper" dir="rtl" >
    <div className="content-wrapper">
    <center>
        <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>
       <h2>
       تـــحت التـــطوير
       </h2>
    </center>
    </div>
    </div>
  )
}

export default Customer_New