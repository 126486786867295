
import moment from 'moment';
import React, { useContext } from 'react';
import QRCode from "react-qr-code";
import { linkLogosResort } from "../../../Helper/links";
import { NAME, RESORT_EMAIL, RESORT_LOGO_PATH, RESORT_NAME_AR, RESORT_NAME_EN, RESORT_PHONE_1, RESORT_WEBSITE } from '../../../Helper/user';
import sum_amount from '../../../functions/sum_total_amount';
import apple_stor from '../../../public/images/apple_stor.svg';
import google_play from '../../../public/images/google_play.png';
import logo from '../../../public/images/logo.png';
import { InvoiceContext } from './Invoice_Context';

import './invoice.css';
let today = new Date();
// let day = today.getDate();
// let month = today.getMonth();
// let year = today.getFullYear();
function InvoicePaymentsYear()
{
    const data = useContext(InvoiceContext)
    const datasort = data;
    return (
    <div className='col-md-12'>
        <div id="invoice" className="page-content fade-in-up" dir="rtl">
            <div className="ibox invoice-print">
                <div className="invoice-header">
                    <div className="row">
                        <div className="col-12">
                        <div className="row">
                               <div className="col-3">
                                    <div className="col-12 text-right" style={{paddingTop:"50px"}}>
                                        <div className="clf" >
                                            <dl className="row pull-right" >
                                                <dt className="col-sm-12">المكتب الحجز</dt>
                                                <dd className="col-sm-12" style={{paddingRight:"15px",margin:"0"}}>طرابلس - غرور</dd>
                                                <div style={{ height: "auto", marginTop:"2px",paddingRight:"15px", maxWidth: 70, width: "100%" }}>
                                                <QRCode
                                                    size={200}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={"https://"+RESORT_WEBSITE}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={linkLogosResort + RESORT_LOGO_PATH} height="150px" width="300px" alt='logo' />
                                        </div>
                                    </center>
                                </div>

                                <div className="col-3">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={logo} height="100px" width="100px"  alt='logo'/>
                                            <br></br>
                                            <img src={apple_stor} height="26px"  alt='logo' style={{marginTop:"5px"}}/>
                                            <br></br>
                                            <img src={google_play} height="35px"   alt='logo'/>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <hr/>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <center>
                                            <h5>
                                            المدفوعات للسنة
                                            -
                                              {" "+moment(datasort[0]?.month).format("  YYYY ")}
                                            </h5>
                                            <h5>

                                            </h5>
                                        </center>
                                        <hr></hr>
                                        <table width="100%">
                                            <thead className="color-view bg- ">
                                                <tr >
                                                    <th width="50%" style={{fontSize:"16px"}}>
                                                    عدد الاشهر : {datasort.length}
                                                    </th>
                                                    <th width="50%" style={{fontSize:"16px"}}>
                                                    إجمالي المبلغ :
                                                    {
                                                        sum_amount(datasort)>=0 ?
                                                        (
                                                            <p className="font-strong" style={{color:"green",display:"inline"}}>
                                                                {" "+sum_amount(datasort)}  د.ل
                                                            </p>
                                                        )
                                                        :
                                                        (
                                                            <p className="font-strong" style={{color:"red",display:"inline"}}>
                                                            {" "+sum_amount(datasort)}  د.ل
                                                            </p>
                                                        )
                                                    }
                                                    </th>
                                                 </tr>
                                            </thead>
                                        </table>
                                        <br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table className="table table-bordered table-striped no-margin table-invoice ">
                    <thead className="color-view  ">
                        <tr >
                            <th>الشهر</th>
                            <th>المبلغ</th>
                        </tr>
                    </thead>
                    <tbody style={{fontSize:"15px"}}>
                        {
                            datasort.map((payment)=>{
                                return(
                                <>
                                <tr>
                                    <td>
                                    {/* {moment(payment.date_payment).format("Y-D-M")} */}
                                     {moment(payment.month).format("YYYY-MM")}
                                     <br></br>
                                     {moment(payment.month).format("MMMM")}
                                    </td>
                                    <td>
                                        {
                                            payment.amount >=0 ?
                                            (
                                                <strong style={{color:"grenn"}}>{payment.amount} د.ل</strong>
                                            )
                                            :
                                            (
                                                <strong style={{color:"red"}}>{payment.amount} د.ل</strong>
                                            )
                                            }
                                    </td>

                                </tr>
                                </>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <table className="table no-border">
                    <thead>
                        <th colSpan={5}>
                            <small style={{margin:"5px"}}>
                            تاريخ الطباعة
                            </small>
                            -
                            <small style={{margin:"5px"}} dir='rtl'>
                            {moment(new Date()).format("YYYY-MM-DD")}
                            </small>
                            -
                            <small style={{margin:"5px"}} dir='ltr'>
                            {moment(new Date()).format("LT")}
                            </small>
                        </th>
                        <tr>
                            <th colSpan={5}>
                            <center>
                            من أجل سلامتك، لا تشارك هذه الفاتورة
                            </center>

                            </th>
                        </tr>
                        <tr>
                            <th>
                                <div className="font-13">
                                <b> {RESORT_NAME_AR} - {RESORT_NAME_EN}  </b> <i className='fa fa-flag'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b >  {RESORT_PHONE_1} </b>  <i className='fa fa-phone'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > www.{RESORT_WEBSITE}  </b> <i className='fa fa-globe'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > {RESORT_EMAIL} </b> <i className="fa fa-envelope"></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > {NAME}  </b> <i className='fa fa-user'></i>
                                </div>
                            </th>
                        </tr>
                    </thead>
            </table>
        </div>
    </div>
    );
}
export default InvoicePaymentsYear;