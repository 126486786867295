
import axios from "axios";
import moment from "moment";
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { useNavigate } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css';
import { Flip, ToastContainer, toast } from 'react-toastify';
import '../Helper/links';
import { UrlًInvoicePdf, UrlًWhatsappMsgInvoice, getapartments_resort, getbookings } from "../Helper/links";
import { pattern } from '../Helper/pattern';
import { InvoiceContext } from '../bookings/invoice/Invoice_Context';
import InvoiceBookinges from "../bookings/invoice/invoice_bookinges";
import before_days from "../functions/print_date_before_days";
import view_apartment_type_ar from "../functions/view_apartment_type";
import view_day_ar from "../functions/view_day_ar";
import '../public/themes/saga-blue/theme.css';

function ReportBookinges()
{

    const Navigate = useNavigate();
    const Completionist = () => <span>تم الغاء الحجز</span>;
    addLocale('ar', {
        firstDayOfWeek: 6,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
        monthNames: ['January-01', 'February-02', 'March-03', 'April-04', 'May-05', 'June-06', 'July-07', 'August-08', 'September-09', 'October-10', 'November-11', 'December-12']    ,
        monthNamesShort: ['Jan-01', 'Feb-02', 'Mar-03', 'Apr-04', 'May-05', 'Jun-06', 'Jul-07', 'Aug-08', 'Sep-09', 'Oct-10', 'Nov-11', 'Dec-12'],
        today: 'اليوم',
        clear: 'تفريغ',
    });
    //_____________________________________________________________________
    const [apartments, setApartments] = useState([]);
    const [apartments_view, setApartments_view] = useState([]);
    const paid_type = ['all','paid','not_paid'];
    const [paid_type_view, setPaidTypeView] = useState('all');
    //_____________________________________________________________________
    const [reservationes, set_reservationes] = useState([]);
    const [reservationes_view, set_reservationes_view] = useState([]);
    const [loading_reservationes_view, set_loading_reservationes_view] = useState(false);
    //_____________________________________________________________________
    const [selectedReservationes, setSelectedReservationes] = useState([]);
    //______________________________________________________________________
    const [search_date, setsearch_date] = useState([]);
    const [name_phone, setname_phone] = useState("");
    const [ID_reservation, setID_reservation] = useState("");
    const [disabled_search, set_disabled_search] = useState(false);
    //_______________________________________________________________________
    const statusBodyTemplate = (reservation) => {
        if(reservation.total_price - reservation.total_paid ===0)
        {
            return <Tag style={{fontFamily:"Cairo",direction:"rtl",fontSize: "17px"}}
                        value={"خالص"} severity="success">
                    </Tag>;
        }
        else
        {
            return <Tag style={{fontFamily:"Cairo",direction:"rtl",fontSize: "17px"}}
                        value={reservation.total_price - reservation.total_paid+"د.ل"} severity="danger">
                    </Tag>;
        }
    };
    const ID_reservationBodyTemplate = (reservation) => {
        var diffInMs   = new Date(moment(reservation.date_del_reservation).format("yyyy-MM-DD")) - new Date(moment(new Date()).format("yyyy-MM-DD"));
        var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            return(
            <>
            {reservation.ID_reservation}

            {

                reservation.date_del_reservation ?
                <span style={{color:"red"}}>
                <br></br>
                <Countdown date={reservation.date_del_reservation}
                renderer={renderer}
                >
                </Countdown>
                <br></br>
                {diffInDays>0? diffInDays+" آيــام ":null}
                </span>
                :null
            }
            </>
            );
    };
    const ApartmentBodyTemplate = (reservation) => {
            return(
            <>
            {  view_apartment_type_ar(reservation.type) + "-" + reservation.number + " "}
            </>
            );
    };
    const PhoneTemplate = (reservation) => {
        return(
        <>
        <a href={"tel:+218"+reservation.phone_number}>{reservation.phone_number}</a>
        <br></br>
        <a href={"tel:+218"+reservation.whatsapp_number}>{reservation.whatsapp_number}</a>
        </>
        );
    };
    const CustomerTemplate = (reservation) => {
        return(
        <>
        {reservation.visitor_name}
        <br></br>
        <a href={"tel:+218"+reservation.phone_number}>{reservation.phone_number}</a>
        <br></br>
        {reservation.whatsapp_number?
        <>
         <a href={"tel:+"+reservation.whatsapp_number} dir="ltr">{reservation.whatsapp_number}</a>
         <i className="fa fa-whatsapp pr-1" style={{color:"green",width:"5px"}}></i>
        </>
        :
        null
        }

        </>
        );
    };
    const Date_CheckInBodyTemplate = (reservation) => {
        return(
        <>
        {moment(reservation.date_check_in).format("YYYY-MM-DD")}
        <br></br>
        {view_day_ar(reservation.date_check_in)}
        </>
        );
    };
    const Date_CheckOutBodyTemplate = (reservation) => {
        return(
        <>
        {moment(reservation.date_check_out).format("YYYY-MM-DD")}
        <br></br>
        {view_day_ar(reservation.date_check_out)}
        </>
        );
    };
    const Total_PriceBodyTemplate = (reservation) => {
        return reservation.total_price+"د.ل";
    };


    const Total_PaidBodyTemplate = (reservation) => {
        return  reservation.total_paid===0? "-":reservation.total_paid+"د.ل";
    };
    const Total_discound_valueBodyTemplate = (reservation) => {
        if(reservation.discount_value>0)
        {
            return (
                <p>{reservation.discount_value+" د.ل"}</p>
            );
        }
        else
        {
            return 0;
        }

    };
    const DateCreate_BodyTemplate = (reservation) => {
        return (
            <p>
            {before_days(reservation.date_create)}
            <br></br>
            {moment(reservation.date_create).format("LT")}
            </p>
        );
    };
    const DateDel_BodyTemplate = (reservation) => {
        var diffInMs   = new Date(moment(reservation.date_del_reservation).format("yyyy-MM-DD")) - new Date(moment(new Date()).format("yyyy-MM-DD"));
        var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return (
            <p>
            {
                reservation.date_del_reservation?
                <>
                 {moment(reservation.date_del_reservation).format("YYYY-MM-DD")}
                 <br></br>
                 {moment(reservation.date_del_reservation).format("LT")}
                 <br></br>
                 {diffInDays>0? " بـعد "+diffInDays+" آيــام ":null}
                </>
                :null
            }
            </p>
        );
    };
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          return <span>
            {hours}:{minutes}:{seconds}

            </span>;
        }
    };
    //____________________________________________________________
    const [Invoice, setInvoice] = useState(false);
    //____________________________________________________________
    const handel_ID_reservation = (e) =>
    {
        if(pattern.number_int.test(e.target.value) === true)
        {
            setID_reservation(e.target.value);
            set_disabled_search(true);
        }
        else if(e.target.value==='' || e.target.value===null)
        {
            setID_reservation(e.target.value);
            set_disabled_search(false);
        }
    }
    const renderHeader = () => {
        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-11">
                        <div className="flex justify-content-between" style={{fontFamily:"Cairo",fontSize:"15px"}} dir="rtl">
                            <InputText
                                value={ID_reservation}
                                onChange={(e) => handel_ID_reservation(e)}
                                placeholder="رقم الحجز"
                                className="col-md-1"
                                maxLength={6}
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}}
                                />
                            <InputText
                                value={name_phone}
                                onChange={(e) => setname_phone(e.target.value)}
                                placeholder="الأسم أو رقم الهاتف"
                                className="col-md-4"
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}} disabled={disabled_search}
                                />
                            <MultiSelect
                                options={apartments}
                                value={apartments_view}
                                itemTemplate={ApartmentsViewItemTemplate}
                                onChange={(e) => setApartments_view(e.target.value)}
                                optionLabel="number"
                                display="chip"
                                placeholder="اختار مكان الاقامة"
                                className="col-md-2"
                                maxSelectedLabels={3}
                                style={{fontFamily:"Cairo",fontSize:"15px",width:"100%"}} disabled={disabled_search}
                                />
                            <Calendar  locale="ar" selectionMode="range" readOnlyInput hideOnRangeSelection className="col-md-2" placeholder="تاريخ الدخول"
                                value={search_date} onChange={(e) => e.value? setsearch_date(e.value):setsearch_date([])} dateFormat="yy-mm-dd" showButtonBar
                                style={{fontFamily: "Cairo",width:"100%",paddingRight:"0px",paddingLeft:"0px"}} disabled={disabled_search}
                                panelStyle={{fontFamily: "Cairo"}}
                                />

                             <Dropdown dir="rtl" value={paid_type_view} valueTemplate={PaidViewTemplate}
                                options={paid_type} onChange={(e) => setPaidTypeView(e.value)}
                                itemTemplate={PaidViewTemplate}  className="col-md-2"
                                panelStyle={{textAlign: 'right',direction:"rtl" }} disabled={disabled_search}/>

                            <Button
                                dir="ltr"
                                type="button"
                                icon="fa fa-remove"
                                outlined
                                severity="danger"
                                onClick={clearFilter}
                                style={{marginRight:"0px"}}
                                />
                           <Button
                                dir="ltr"
                                type="button"
                                icon="fa fa-search"
                                outlined
                                onClick={search}
                                style={{marginRight:"5px"}}
                                />

                        </div>
                    </div>
                    <div className="col-md-1" style={{textAlign:"left"}}>
                        <Button icon="fa fa-refresh" rounded raised onClick={()=>{
                            GetAllReservationes()
                        }} />
                    </div>
                </div>

          </div>
        );
    };
    const search = (e) => {
        e.preventDefault();
        // setname_phone(e.target.value);
        // setcheck_in("");
        // SearchReservationes();
        // If the text field is empty, show all users
       //___________________________________________________________________
       //                  Search On Old Reservationes
        var reservationes_search_apt = [];
        var reservationes_search_coming = [];
        reservationes.map((res) =>
        (
            // eslint-disable-next-line array-callback-return
            apartments_view.map((apt)=>{
                if(
                  res.ID_apartment === apt.ID_apartment
                )
                {
                    reservationes_search_apt.push(res);
                }
            })
        ))
        // eslint-disable-next-line array-callback-return
        reservationes_search_apt.map((res) => {
            if(ID_reservation !=='' && ID_reservation !== false)
            {
                if(
                    (res.ID_reservation.toString()).startsWith(ID_reservation)
                 || (res.ID_reservation.toString()).endsWith(ID_reservation)
                 || res.ID_reservation === ID_reservation
             )
             {
                reservationes_search_coming.push(res);
             }
            }
            else if(
                  (
                    res.visitor_name.startsWith(name_phone.toString())
                    || res.visitor_name.endsWith(name_phone.toString())
                    //_____________________________________________
                    || (res.phone_number.toString()).startsWith(name_phone.toString())
                    || (res.phone_number.toString()).endsWith(name_phone.toString())
                    //_____________________________________________
                    || (res.whatsapp_number!==null && (res.whatsapp_number.toString()).startsWith(name_phone.toString()))
                    || (res.whatsapp_number!==null && (res.whatsapp_number.toString()).endsWith(name_phone.toString()))
                  )
                  &&
                //_____________________________________________
                 (
                    (paid_type_view==='paid' &&  res.total_paid===res.total_price)
                    ||
                    (paid_type_view==='not_paid' &&  res.total_paid!==res.total_price)
                    ||
                    paid_type_view==='all'
                 )
                 &&
                 (
                    (
                        search_date.length>0
                        &&
                        moment(res.date_check_in).format('yyyy-MM-DD') >= moment(search_date[0]).format('yyyy-MM-DD')
                        &&
                        (
                            (
                                search_date.length>1
                                &&
                                moment(res.date_check_in).format('yyyy-MM-DD') <= moment(search_date[1]).format('yyyy-MM-DD')
                            )
                            || search_date[1]===null
                        )
                    )
                    ||
                    search_date===null || search_date.length===0

                 )

            )
            {
                reservationes_search_coming.push(res);
            }
        })
        set_reservationes_view(reservationes_search_coming);
    };
    const clearFilter = () => {
        setID_reservation("")
        setname_phone("");
        setsearch_date([])
        setPaidTypeView('all')
        GetApartment();
        GetAllReservationes()
    };
    const print = () => {
       setInvoice(true);
    };
    //_______________________________________________________________________________________
    //                      Get Reservationes
    // const SearchReservationes = async () =>
    // {
    //     var response = await axios.post(getsearchbookings,{
    //         "TOKEN": window.localStorage.getItem('token'),
    //         "name_phone" : name_phone,
    //         "id_aprtment" : "",
    //         "check_in" : check_in,
    //         "del":0,
    //     });
    //     if(response.status===200)
    //     {

    //         if (response.data.success === 500 || response.data.error === 1)
    //         {
    //             Navigate('/error', {
    //                 state: {
    //                     msg: response.data.msg
    //                 }
    //             })
    //         }
    //         else if(response.data.success===1)
    //         {
    //             setReservationes_coming(response.data.reservationes);
    //         }
    //         else if(response.data.success===0)
    //         {
    //             setReservationes_coming("");
    //         }
    //     }
    // }
    const GetApartment = async () =>
    {
        var response = await axios.post(getapartments_resort,{"TOKEN": window.localStorage.getItem('token'),"del":0});

        if(response.status===200)
        {
            if (response.data.success === 500 || response.data.error === 1)
            {
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                setApartments(response.data.apartments);
                setApartments_view(response.data.apartments);
            }
        }
    }
    const GetAllReservationes = async () =>
    {
        set_loading_reservationes_view(true)
        var response = await axios.post(getbookings,{"TOKEN": window.localStorage.getItem('token'),"del":0});
        if(response.status===200)
        {
            set_loading_reservationes_view(false)
            if (response.data.success === 500 || response.data.error === 1)
            {
                Navigate('/error', {
                    state: {
                        msg: response.data.msg
                    }
                })
            }
            else if(response.data.success===1)
            {
                set_reservationes(response.data.reservationes);
              //__________________________________________________________
                set_reservationes_view(response.data.reservationes);
              // Filter Reservationes

            }

        }
    }
    const ApartmentsViewItemTemplate = (apt) => {
        return (
            <div className="flex align-items-center gap-1" style={{fontFamily:"Cairo",direction:"rtl"}} >
                <span>{view_apartment_type_ar(apt.type)} - {apt.number}</span>
            </div>
        );
    };
    const PaidViewTemplate = (value) => {
        return (
          <>
           <Tag style={{fontFamily:"Cairo",direction:"rtl",fontSize: "13px",textAlign:"right",maxHeight:"22px"}}
             value={value==='all'?'الكل':value==='paid'?"خالص":"غير خالص"}  severity={value==='all'? 'info':value==='paid'?"success":"danger"}>
            </Tag>
          </>
        );
    };
    //                      Send whatsapp pdf
    const Get_Send_Whatsapp_Pdf= async (data,msg) =>
    {
        if(data.ID_customer && data.whatsapp_number)
        {
            var url =UrlًInvoicePdf+'?id='+data.ID_customer+'&token='+window.localStorage.getItem('token');
            var response = await axios.post(UrlًWhatsappMsgInvoice,{
            'type':'invoice',
            'number':data.whatsapp_number,
            'msg':msg,
            'url':url
            }
            ,{
            headers : {
                'Authorization' : 'Bearer '+window.localStorage.getItem('token')
                },
            });
            if(response.status===200)
            {
            if(response.data.success===1)
            {
                toast.success(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
            else if(response.data.success===0)
            {
                toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

            }
            }
        }
    }
    useEffect(() =>
    {
        GetApartment();
        GetAllReservationes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <div className="page-wrapper" dir="rtl" >
        <div className="content-wrapper">
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Flip}
            />
            <div className="page-heading">
                <h1 className="page-title"> تقـاريـر الحجــوزات</h1>
            </div>

            <DataTable value={reservationes_view} loading={loading_reservationes_view}
                dataKey="ID_reservation"
                selectionMode="multiple" selection={selectedReservationes} onSelectionChange={(e) => setSelectedReservationes(e.value)}
                metaKeySelection={false} dragSelection
                stripedRows removableSort
                // paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50]}
                scrollable scrollHeight="750px"
                sortMode="multiple"
                size="normal"
                header={renderHeader}
                emptyMessage={<center><h4>لا يوجد حجوزات</h4></center>}
                tableStyle={{ minWidth: '50rem' }}
                style={{fontFamily:"Cairo",direction:"rtl",fontSize: "17px",border: "10px"}}
            >
                <Column selectionMode="multiple"  style={{ width: '1%',textAlign:"right" }}></Column>
                <Column field="ID_reservation" body={ID_reservationBodyTemplate}  header="الحجز" sortable style={{ width: '2%',textAlign:"right" }}></Column>
                <Column field="visitor_name" body={CustomerTemplate} header="الزبون" sortable style={{ width: '10%',textAlign:"right" }}></Column>
                <Column field="number" header="الاقامة"  showFilterMenu={false} filterMenuStyle={{ width: '14rem' }}
                    body={ApartmentBodyTemplate} sortable style={{ width: '5%',textAlign:"right" }}></Column>
                <Column field="date_check_in" header="الدخول" body={Date_CheckInBodyTemplate} sortable  style={{ width: '10%',textAlign:"right" }}></Column>
                <Column field="date_check_out" header="الخروج" body={Date_CheckOutBodyTemplate} sortable style={{ width: '10%',textAlign:"right" }}></Column>
                <Column field="total_price" header="السعر"   body={Total_PriceBodyTemplate}  sortable style={{ width: '5%',textAlign:"right" }}></Column>
                <Column field="total_paid" header="المدفوع" body={Total_PaidBodyTemplate} sortable style={{ width: '5%',textAlign:"right",color:"green" }}></Column>
                <Column header="الباقي" field="rest" body={statusBodyTemplate} sortable style={{ width: '5%',textAlign:"right",color:"red" }} ></Column>
                <Column field="discount_value" header="التخفيض" body={Total_discound_valueBodyTemplate}   sortable style={{ width: '5%',textAlign:"right" }}></Column>
                <Column field="notes" header="ملاحظات" style={{ width: '5%',textAlign:"right" }}></Column>
                <Column field="date_del_reservation" header="وفت الغاء الحجز" body={DateDel_BodyTemplate} sortable  style={{ width: '10%',textAlign:"right" }}></Column>
                <Column field="date_create" header="وثت الحجز" body={DateCreate_BodyTemplate}   sortable style={{ width: '10%',textAlign:"right" }}></Column>
                <Column field="name" header="الحجز عن طريق" style={{ width: '10%',textAlign:"right" }}></Column>
            </DataTable>
             {
                Invoice ?
                (
                <Dialog dir="rtl" header="فاتورة الحجوزات"    visible={Invoice} maximizable  style={{ width: '50vw',fontFamily:"Cairo" }} onHide={() => setInvoice(false)}>
                    <InvoiceContext.Provider value={selectedReservationes} >
                    {Invoice &&  <InvoiceBookinges></InvoiceBookinges>}
                    </InvoiceContext.Provider>
                </Dialog>
                )
                :
                (null)
             }


            <div className="ibox-body" style={{textAlign:"center",padding:"10px"}}>
                <div className="row" style={{fontSize:"20px"}}>
                    <div className="col-md-6" >
                        {
                            selectedReservationes.length > 0?
                            (
                                <>
                                <Button
                                dir="ltr"
                                type="button"
                                icon="fa fa-close"
                                className="col-md-1"
                                outlined
                                onClick={()=>{setSelectedReservationes([])}}
                                />
                                <strong className="col-md-10">   تحديد {selectedReservationes.length} حجوزات </strong>
                                <>
                                    <Button dir='ltr' type="button"  label=" عــرض "  icon='fa fa-file' raised
                                    onClick={print}
                                    />
                                </>

                                </>
                            )
                            :
                            (null)
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ReportBookinges


