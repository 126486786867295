
import moment from 'moment';
import React, { useContext } from 'react';
import QRCode from "react-qr-code";
import { linkLogosResort } from "../../../Helper/links";
import { NAME, RESORT_EMAIL, RESORT_LOGO_PATH, RESORT_NAME_AR, RESORT_NAME_EN, RESORT_PHONE_1, RESORT_WEBSITE } from '../../../Helper/user';
import count_days from '../../../functions/count_days';
import sum_amount from '../../../functions/sum_total_amount';
import view_apartment_type_ar from '../../../functions/view_apartment_type';
import view_day_ar from '../../../functions/view_day_ar';
import apple_stor from '../../../public/images/apple_stor.svg';
import google_play from '../../../public/images/google_play.png';
import logo from '../../../public/images/logo.png';
import { InvoiceContext } from './Invoice_Context';
import './invoice.css';

function InvoicePaymentsDay()
{
    const data = useContext(InvoiceContext)
    const datasort = data;
    return (
    <div className='col-md-12'>
        <div id="invoice" className="page-content fade-in-up" dir="rtl" style={{padding:"10px"}}>
            <div >
                <div className="invoice-header">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                               <div className="col-3">
                                    <div className="col-12 text-right" style={{paddingTop:"50px"}}>
                                        <div className="clf" >
                                            <dl className="row pull-right" >
                                                <dt className="col-sm-12">المكتب الحجز</dt>
                                                <dd className="col-sm-12" style={{paddingRight:"15px",margin:"0"}}>طرابلس - غرور</dd>
                                                <div style={{ height: "auto", marginTop:"2px",paddingRight:"15px", maxWidth: 70, width: "100%" }}>
                                                <QRCode
                                                    size={200}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={"https://"+RESORT_WEBSITE}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                </div>
                                            </dl>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={linkLogosResort + RESORT_LOGO_PATH} height="150px" width="300px" alt='logo' />
                                        </div>
                                    </center>
                                </div>

                                <div className="col-3">
                                    <center>
                                        <div className="invoice-logo">
                                            <img src={logo} height="100px" width="100px"  alt='logo'/>
                                            <br></br>
                                            <img src={apple_stor} height="26px"  alt='logo' style={{marginTop:"5px"}}/>
                                            <br></br>
                                            <img src={google_play} height="35px"   alt='logo'/>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <hr/>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <center>
                                            <h5>
                                            مدفوعات
                                            </h5>
                                            <h5>
                                                {" "+view_day_ar(datasort[0]?.date_payment)}
                                                {" "+moment(datasort[0]?.date_payment).format("DD-MM-Y")}
                                            </h5>
                                        </center>
                                        <hr></hr>
                                        <table width="100%">
                                            <thead className="color-view bg- ">
                                                <tr >
                                                    <th width="50%" style={{fontSize:"16px"}}>
                                                    عدد الفواتير : {datasort.length}
                                                    </th>
                                                    <th width="50%" style={{fontSize:"16px"}}>
                                                    إجمالي المبلغ :
                                                    {
                                                        sum_amount(datasort)>=0 ?
                                                        (
                                                            <p className="font-strong" style={{color:"green",display:"inline"}}>
                                                                {" "+sum_amount(datasort)}  د.ل
                                                            </p>
                                                        )
                                                        :
                                                        (
                                                            <p className="font-strong" style={{color:"red",display:"inline"}}>
                                                            {" "+sum_amount(datasort)}  د.ل
                                                            </p>
                                                        )
                                                    }
                                                    </th>
                                                 </tr>
                                            </thead>
                                        </table>
                                        <br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-bordered table-striped no-margin table-invoice">
                    <thead className="color-view bg- " >
                        <tr >
                            <th style={{fontSize:"12px"}}>الوقت</th>
                            <th style={{fontSize:"12px"}}> الفاتورة</th>
                            <th style={{fontSize:"12px"}}>الزبون</th>
                            <th style={{fontSize:"12px"}}>الشالية</th>
                            <th style={{fontSize:"12px"}}>أيام</th>
                            <th style={{fontSize:"12px"}}>الحجز</th>
                            <th style={{fontSize:"12px"}}>السعر</th>
                            <th style={{fontSize:"12px"}}>العملية</th>
                            <th style={{fontSize:"12px"}}>المبلغ</th>
                            <th style={{fontSize:"12px"}}>حالته</th>
                            <th style={{fontSize:"12px"}}>الموظف</th>

                        </tr>
                    </thead>
                    <tbody style={{fontSize:"12px"}}>
                        {
                            datasort.map((payment)=>{
                                return(
                                <>
                                <tr>
                                    <td>
                                    {/* {moment(payment.date_payment).format("Y-D-M")} */}
                                     {moment(payment.date_payment).format("h:m A")}
                                    </td>
                                    <td>{payment.ID_payment}</td>
                                    <td>{payment.visitor_name}</td>
                                    <td>
                                        <div>
                                        <small>{view_apartment_type_ar(payment.type)}</small><strong>{"-"+ payment.number}</strong>
                                        </div>
                                    </td>

                                    <td >{count_days([payment])} أيام</td>

                                    <td>{payment.ID_reservation} </td>
                                    <td><strong >{payment.total_price} د.ل</strong></td>
                                    <td><strong >{payment.operation_type}</strong></td>
                                    <td>
                                        {
                                            payment.amount >=0 ?
                                            (
                                                <strong style={{color:"green"}}>{payment.amount} د.ل</strong>
                                            )
                                            :
                                            (
                                                <strong style={{color:"red"}}>{payment.amount} د.ل</strong>
                                            )
                                            }
                                    </td>
                                    <td>
                                      {
                                        payment.del ===0?
                                        (

                                                payment.total_price-payment.total_paid ===0 ?
                                                (
                                                    <strong style={{color:"green"}}>
                                                        خالص
                                                    </strong>
                                                )
                                                :
                                                (
                                                    <strong style={{color:"red"}}>
                                                    مدفوع جزاء
                                                    </strong>
                                                )

                                        )
                                        :
                                        (
                                            <strong style={{color:"red"}}>
                                             ملغي
                                            </strong>
                                        )
                                      }
                                    </td>
                                    <td><strong >{payment.name_user}</strong></td>
                                </tr>
                                </>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>

            <hr></hr>
            <table className="table no-border">
                    <thead>
                        <th colSpan={5}>

                                  <small style={{margin:"5px"}}>
                                 تاريخ الطباعة
                                </small>
                                -
                                <small style={{margin:"5px"}} dir='rtl'>
                                    {moment(new Date()).format("YYYY-MM-DD")}
                                </small>
                                -
                                <small style={{margin:"5px"}} dir='ltr'>
                                    {moment(new Date()).format("LT")}
                                </small>
                            </th>
                        <tr>
                            <th colSpan={5}>
                                <center>
                                من أجل سلامتك، لا تشارك هذه الفاتورة
                                </center>

                            </th>
                        </tr>
                        <tr>
                            <th>
                                <div className="font-13">
                                <b> {RESORT_NAME_AR} - {RESORT_NAME_EN}  </b> <i className='fa fa-flag'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b >  {RESORT_PHONE_1} </b>  <i className='fa fa-phone'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > www.{RESORT_WEBSITE}  </b> <i className='fa fa-globe'></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > {RESORT_EMAIL} </b> <i className="fa fa-envelope"></i>
                                </div>
                            </th>
                            <th>
                                <div className="font-13">
                                <b > {NAME}  </b> <i className='fa fa-user'></i>
                                </div>
                            </th>
                        </tr>
                    </thead>
            </table>
        </div>

    </div>
    );
}
export default InvoicePaymentsDay;