export const TOKEN = window.localStorage.getItem('token');
export const USERNAME = window.localStorage.getItem('username');
export const NAME = window.localStorage.getItem('name');
export const RESORT_NAME_EN = window.localStorage.getItem('resort_name_en');
export const RESORT_NAME_AR = window.localStorage.getItem('resort_name_ar');
export const SHORT_RESORT_NAME = window.localStorage.getItem('short_resort_name');
export const RESORT_PHONE_1 = window.localStorage.getItem('resort_phone_1');
export const RESORT_PHONE_2 = window.localStorage.getItem('resort_phone_2');
export const RESORT_EMAIL = window.localStorage.getItem('resort_email');
export const RESORT_WEBSITE = window.localStorage.getItem('resort_website');
export const RESORT_LOGO_PATH = window.localStorage.getItem('resort_logo_path');

