
import React, { useContext } from 'react';

import moment from 'moment';
import count_days from '../../functions/count_days';
import sum_total_paid from '../../functions/sum_total_paid';
import sum_total_price from '../../functions/sum_total_price';
import view_apartment_type_ar from '../../functions/view_apartment_type';
import view_day_ar from '../../functions/view_day_ar';
import { InvoiceContext } from './Invoice_Context';
import './invoice.css';

function PaidInvoice()
{
    const data = useContext(InvoiceContext)
    var monthView = 0;
    const datasort = [...data.reservationes].sort((a, b) =>
                        a.date_check_in > b.date_check_in ? 1 : -1,
                    );

    return (
    <div>
        <div id="invoice" className="page-content fade-in-up" dir="rtl"  style={{margin:"auto",maxWidth:"850PX"}}  >
            <div className="ibox invoice-print">
                <div className="invoice-header">
                    <hr></hr>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6" style={{borderLeft:"1px solid rgba(0,0,0,.1"}}>
                                <div className="m-b-5 font-bold" >بيانات الزبون</div>
                                <ul className="list-unstyled m-t-10">
                                    <li className="m-b-5">
                                        <span className="font-strong"> الأسم  :</span> {data.visitor_name ? data.visitor_name:datasort[0].visitor_name} </li>
                                    <li className="m-b-5">
                                        <span className="font-strong"> الهاتف :</span> {data.phone_number ? data.phone_number:datasort[0].phone_number}</li>
                                 {data.whatsapp_number ?
                                 (<li className="m-b-5" > <span className="font-strong" > واتس اب :</span> {data.whatsapp_number}</li>)
                                 :(null)
                                 }
                                </ul>
                            </div>

                            <div className="col-6" style={{borderRight:"1px solid rgba(0,0,0,.1"}}>
                                <div className="m-b-5 font-bold">بيانات الحجوزات</div>
                                <ul className="list-unstyled m-t-10">
                                    <li className="m-b-5">
                                        <span className="font-strong"> عدد الحجوزات : </span> {datasort.length} </li>
                                    <li className="m-b-5">
                                        <span className="font-strong">  إجمالي الأيام : </span>  {count_days(datasort)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                </div>
                <table className="table table-bordered table-striped no-margin table-invoice">
                    <thead className="color-view bg-ebony">
                        <tr style={{color:"white",fontSize:"10px"}}>
                            <th>الحجز</th>
                            <th>الاقامة</th>
                            <th>الأيام</th>
                            <th colSpan={2} style={{textAlign:"center"}} >
                             الدخول - الخروج
                            </th>
                            <th>سعر اليـوم</th>
                            <th>الإجمالي</th>
                            <th>المدفوع</th>
                            <th>الباقي</th>
                            <th style={{textAlign:"center",width:"10%"}}>تاريخ  إلغاء </th>
                        </tr>
                    </thead>
                    <tbody style={{fontSize:"13px"}} >
                        {
                            datasort.map((res)=>{
                                return(
                                <>
                                {
                                    monthView !== moment(res.date_check_in).format('MM')?
                                    (
                                        <tr>
                                            <td colSpan={10}>
                                                <center>
                                                    <strong >
                                                    {" حجوزات شهر " }
                                                     { monthView = moment(res.date_check_in).format('MM')}
                                                    {" " + moment(res.date_check_in).format('MMMM') + " "}
                                                    </strong>
                                                </center>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    (null)
                                }
                                <tr>
                                    <td>{res.ID_reservation}</td>
                                    <td>
                                        <div>
                                        <small>{view_apartment_type_ar(res.type)}</small><strong>{"-"+ res.number}</strong>
                                        </div>
                                        <small>{res.number_people} أشخاص </small>
                                    </td>

                                    <td style={{width:"5s%"}}>{count_days([res])} أيام</td>
                                    <td >
                                        <div>
                                            <strong >{res.date_check_in} </strong>
                                        </div>
                                        <small> {view_day_ar(res.date_check_in)} </small>
                                    </td>
                                    <td>
                                        <div>
                                            <strong>{res.date_check_out}  </strong>
                                        </div>
                                        <small>  {view_day_ar(res.date_check_out)} </small>
                                    </td>
                                    <td>{res.price_day}  د.ل</td>
                                    <td><strong >{res.total_price} د.ل</strong></td>
                                    <td><strong >{res.total_paid} د.ل</strong></td>
                                    <td>
                                        {
                                            res.total_price-res.total_paid ===0 ?
                                            (
                                                <strong style={{color:"green"}}>
                                                    خالص
                                                </strong>
                                            )
                                            :
                                            (
                                                <strong style={{color:"red"}}>
                                                 {res.total_price-res.total_paid} د.ل
                                                </strong>
                                            )
                                        }
                                    </td>
                                    <td >
                                        {
                                            res.date_del_reservation?
                                            <strong style={{color:"red"}}>
                                                {moment(res.date_del_reservation).format('MM-DD')}
                                                <br></br>
                                                {moment(res.date_del_reservation).format('LT')}
                                            </strong>
                                            :"-"
                                        }
                                    </td>
                                </tr>
                                </>
                                );
                            })
                        }
                    </tbody>
                </table>
                <table className="table no-border">
                    <thead >
                        <tr>
                            <td className="font-bold font-18 text-center">إجمالي المبلغ  </td>
                            <td className="font-bold font-18 text-center">إجمالي المدفوع  </td>
                            <td className="font-bold font-18 text-center">إجمالي المتبقي  </td>
                        </tr>
                    </thead>
                    <tbody  >
                        <tr>
                            <td  className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_price(datasort)}
                                 د.ل</td>
                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>{sum_total_paid(datasort)} د.ل</td>
                            <td className="font-bold font-18 text-center" style={{padding:"1px"}}>
                            {
                                sum_total_price(datasort)-sum_total_paid(datasort) ===0 ?
                                (
                                    <strong style={{color:"green"}}>
                                        خالص
                                    </strong>
                                )
                                :
                                (
                                    <strong style={{color:"red"}}>
                                        {sum_total_price(datasort)-sum_total_paid(datasort)} د.ل
                                    </strong>
                                )
                            }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    );
}
export default PaidInvoice;